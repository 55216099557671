// Message cache utility functions

// Clear all cached messages for a user
export const clearUserMessageCache = (userId) => {
    // Clear localStorage cache
    const keys = Object.keys(localStorage);
    keys.forEach(key => {
        if (key.includes('messages_') || key.includes('chat_') || key.includes('draft_')) {
            localStorage.removeItem(key);
        }
    });

    // Clear sessionStorage cache
    const sessionKeys = Object.keys(sessionStorage);
    sessionKeys.forEach(key => {
        if (key.includes('messages_') || key.includes('chat_') || key.includes('draft_')) {
            sessionStorage.removeItem(key);
        }
    });
};

// Clear specific chat messages from cache
export const clearChatMessageCache = (userId, chatId) => {
    localStorage.removeItem(`messages_${userId}_${chatId}`);
    localStorage.removeItem(`chat_${userId}_${chatId}`);
    localStorage.removeItem(`draft_${userId}_${chatId}`);
    sessionStorage.removeItem(`messages_${userId}_${chatId}`);
    sessionStorage.removeItem(`chat_${userId}_${chatId}`);
    sessionStorage.removeItem(`draft_${userId}_${chatId}`);
};

// Clear all message-related data from IndexedDB
export const clearIndexedDBMessageCache = async () => {
    try {
        const dbs = await window.indexedDB.databases();
        for (const db of dbs) {
            if (db.name.includes('messages') || db.name.includes('chat')) {
                window.indexedDB.deleteDatabase(db.name);
            }
        }
    } catch (error) {
        console.error('Error clearing IndexedDB cache:', error);
    }
}; 