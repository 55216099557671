import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff, ArrowBack, Person, Lock, CheckCircle, DarkMode, LightMode } from '@mui/icons-material';
import { handlePasswordChange, verifyPassword } from '../services/PasswordService';
import { validatePassword, getPasswordStrengthColor, renderCapsLockWarning } from '../utils/passwordUtils';
import { toast } from 'react-hot-toast';
import Notifications from './Notifications';

export default function ChangePasswordForm({ onClose, isAdminReset = false, tempPassword = '', username: initialUsername = '', isDarkMode, onThemeToggle }) {
    const navigate = useNavigate();
    const [step, setStep] = useState(isAdminReset ? 2 : 1);
    const [username, setUsername] = useState(initialUsername);
    const [currentPassword, setCurrentPassword] = useState('');
    const [tempPasswordInput, setTempPasswordInput] = useState(tempPassword);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showTempPassword, setShowTempPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [verificationSuccess, setVerificationSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState({ score: 0, message: '', requirements: {}, isStrong: false });
    const [isCapsLockOn, setIsCapsLockOn] = useState(false);

    const handleKeyDown = (e) => {
        setIsCapsLockOn(e.getModifierState('CapsLock'));
    };

    const handleKeyUp = (e) => {
        setIsCapsLockOn(e.getModifierState('CapsLock'));
    };

    const handleVerifyPassword = async () => {
        if (!username || !currentPassword) {
            toast.error('Please fill in all fields');
            return;
        }

        setIsLoading(true);
        try {
            await verifyPassword(username, currentPassword);
            setStep(2);
            setError('');
            setVerificationSuccess(true);
            toast.success('Password verified successfully');
        } catch (err) {
            toast.error(err.response?.data?.detail || 'Failed to verify current password');
        } finally {
            setIsLoading(false);
        }
    };

    const handleChangePassword = async (e) => {
        e.preventDefault();
        if (!newPassword || !confirmPassword) {
            toast.error('Please fill in all fields');
            return;
        }

        if (newPassword !== confirmPassword) {
            toast.error('Passwords do not match');
            return;
        }

        if (!passwordStrength.isStrong) {
            toast.error('Please choose a stronger password');
            return;
        }

        setIsLoading(true);
        try {
            await handlePasswordChange(username, currentPassword, newPassword, isAdminReset, tempPasswordInput);
            setSuccess(true);
            toast.success('Password changed successfully');
            setTimeout(() => {
                onClose();
                navigate('/login');
            }, 2000);
        } catch (err) {
            toast.error(err.response?.data?.detail || 'Failed to change password');
        } finally {
            setIsLoading(false);
        }
    };

    const handleNewPasswordChange = (e) => {
        const newPass = e.target.value;
        setNewPassword(newPass);
        setPasswordStrength(validatePassword(newPass, confirmPassword));
        setError('');
    };

    const handleConfirmPasswordChange = (e) => {
        const confirmPass = e.target.value;
        setConfirmPassword(confirmPass);
        setPasswordStrength(validatePassword(newPassword, confirmPass));
        setError('');
    };

    const handleBackToLogin = () => {
        navigate('/login');
    };

    const renderVerificationForm = () => (
        <form className="auth-form" onSubmit={(e) => {
            e.preventDefault();
            if (!verificationSuccess && !isLoading && username && currentPassword) {
                handleVerifyPassword();
            }
        }}>
            {verificationSuccess && (
                <div className="success-message verification-success">
                    <CheckCircle className="success-icon" />
                    Current password verified successfully!
                </div>
            )}
            
            <div className="input-container">
                <Person className="input-icon" />
                <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="auth-input"
                    required
                    disabled={isAdminReset}
                />
            </div>

            <div className="input-container">
                <Lock className="input-icon" />
                <input
                    type={showCurrentPassword ? "text" : "password"}
                    placeholder="Current Password"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                    onKeyUp={handleKeyUp}
                    className="auth-input"
                    required
                />
                <button
                    type="button"
                    className="visibility-toggle"
                    onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                    tabIndex="-1"
                >
                    {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                </button>
            </div>

            {renderCapsLockWarning(isCapsLockOn)}

            <button 
                type="submit" 
                className={`submit-button ${isLoading ? 'loading' : ''}`}
                disabled={verificationSuccess || isLoading || !username || !currentPassword}
            >
                {isLoading ? 'Verifying...' : 'Verify Password'}
            </button>
        </form>
    );

    const renderPasswordChangeForm = () => (
        <form className="auth-form" onSubmit={handleChangePassword}>
            {isAdminReset && (
                <>
                    <div className="input-container">
                        <Person className="input-icon" />
                        <input
                            type="text"
                            placeholder="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                            className="auth-input"
                            disabled
                        />
                    </div>

                    <div className="input-container">
                        <Lock className="input-icon" />
                        <input
                            type={showTempPassword ? "text" : "password"}
                            placeholder="Temporary Password"
                            value={tempPasswordInput}
                            onChange={(e) => setTempPasswordInput(e.target.value)}
                            onKeyDown={handleKeyDown}
                            onKeyUp={handleKeyUp}
                            className="auth-input"
                            required
                        />
                        <button
                            type="button"
                            className="visibility-toggle"
                            onClick={() => setShowTempPassword(!showTempPassword)}
                            tabIndex="-1"
                        >
                            {showTempPassword ? <VisibilityOff /> : <Visibility />}
                        </button>
                    </div>
                </>
            )}

            <div className="input-container">
                <Lock className="input-icon" />
                <input
                    type={showNewPassword ? "text" : "password"}
                    placeholder="New Password"
                    value={newPassword}
                    onChange={handleNewPasswordChange}
                    onKeyDown={handleKeyDown}
                    onKeyUp={handleKeyUp}
                    className="auth-input"
                    required
                />
                <button
                    type="button"
                    className="visibility-toggle"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    tabIndex="-1"
                >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                </button>
            </div>

            <div className="input-container">
                <Lock className="input-icon" />
                <input
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm New Password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    onKeyDown={handleKeyDown}
                    onKeyUp={handleKeyUp}
                    className="auth-input"
                    required
                />
                <button
                    type="button"
                    className="visibility-toggle"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    tabIndex="-1"
                >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </button>
            </div>

            {newPassword && (
                <div className="password-strength-container">
                    <div className="password-strength-bar">
                        <div 
                            className="password-strength-progress"
                            style={{
                                width: `${(passwordStrength.score / 6) * 100}%`,
                                backgroundColor: getPasswordStrengthColor(passwordStrength.score)
                            }}
                        />
                    </div>
                    <div className="password-strength-text">
                        <span className="strength-label">Password Strength:</span>
                        <span 
                            className="strength-message" 
                            style={{ color: getPasswordStrengthColor(passwordStrength.score) }}
                        >
                            {passwordStrength.message}
                        </span>
                    </div>
                    <div className="password-requirements">
                        <div className={passwordStrength.requirements.length ? 'met' : ''}>
                            • At least 8 characters
                        </div>
                        <div className={passwordStrength.requirements.uppercase ? 'met' : ''}>
                            • At least one uppercase letter
                        </div>
                        <div className={passwordStrength.requirements.lowercase ? 'met' : ''}>
                            • At least one lowercase letter
                        </div>
                        <div className={passwordStrength.requirements.number ? 'met' : ''}>
                            • At least one number
                        </div>
                        <div className={passwordStrength.requirements.special ? 'met' : ''}>
                            • At least one special character
                        </div>
                        <div className={passwordStrength.requirements.match ? 'met' : ''}>
                            • Passwords match
                        </div>
                    </div>
                </div>
            )}

            {renderCapsLockWarning(isCapsLockOn)}

            <button 
                type="submit" 
                className={`submit-button ${isLoading ? 'loading' : ''}`}
                disabled={isLoading || !newPassword || !confirmPassword || !passwordStrength.isStrong}
            >
                {isLoading ? 'Changing Password...' : 'Change Password'}
            </button>

            {success && (
                <div className="success-message">
                    Password changed successfully! Redirecting to login...
                </div>
            )}
        </form>
    );

    return (
        <div className={`auth-page ${isDarkMode ? 'dark-theme' : ''}`}>
            <Notifications />
            <div className="auth-container">
                <div className="auth-header">
                    <h1>Change Password</h1>
                    <button
                        onClick={onThemeToggle}
                        className="theme-toggle-btn"
                    >
                        {isDarkMode ? <LightMode /> : <DarkMode />}
                    </button>
                </div>
                {error && <div className="error-message">{error}</div>}
                {step === 1 ? renderVerificationForm() : renderPasswordChangeForm()}
                
                <div className="auth-links">
                    <button
                        type="button"
                        className="submit-button"
                        onClick={handleBackToLogin}
                    >
                        <ArrowBack /> Back to Login
                    </button>
                </div>
            </div>
        </div>
    );
}
