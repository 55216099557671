import React, { forwardRef, useEffect, useCallback, useState, memo, useRef } from 'react';

// MessageSearchInput.js
const MessageSearchInput = memo(forwardRef(({ 
    initialValue,
    onSearch,
    onClear, 
    onClose,
    isTypingMessage,
    setIsTypingMessageSearchBar,
    isTypingMessageSearchBar,
}, ref) => {
    const [inputValue, setInputValue] = useState(initialValue);
    const inputRef = useRef(null);

    useEffect(() => {
        setInputValue(initialValue);
    }, [initialValue]);

    const handleChange = useCallback((e) => {
        const value = e.target.value;
        setInputValue(value);  // Update local state immediately
        setIsTypingMessageSearchBar(true);
        onSearch(value);  // Realtime search
    }, [onSearch]);

    const handleKeyDown = useCallback((e) => {
        if (e.key === 'Escape') {
            onClose();
            setIsTypingMessageSearchBar(false);
        }
    }, [onClose, setIsTypingMessageSearchBar]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    return (
        <div className="search-input-wrapper">
            <input
                className="search-messages-input"
                type="text"
                value={inputValue}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                autoComplete="off"
                spellCheck="false"
                ref={inputRef}
            />
            {inputValue && (
                <button className="search-messages-close" onClick={onClear}>
                ✗
                </button>
            )}
            <button className="search-messages-close" onClick={onClose}>
                ✕
            </button>
        </div>
    );
}));

export default MessageSearchInput;