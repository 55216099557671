// MemoizedSearchResults.js
import React, { memo, useMemo } from 'react';
import { groupMessagesByDate } from './UtilityFunc';

const MessageResultGroup = memo(({ date, messages, user, formatTime, onSelect }) => (
    <div className="search-result-group">
        <div className="search-result-date">{date}</div>
        {messages.map((message) => (
            <div
                key={message.id}
                className="search-result-item"
                onClick={() => onSelect(message.id)}
            >
                <div className="search-result-content">
                    {message.content}
                </div>
                <div className="search-result-info">
                    <span>
                        {message.sender_id === user?.id ? 'You' : message.sender_name}
                    </span>
                    <span>{formatTime(message.timestamp)}</span>
                </div>
            </div>
        ))}
    </div>
));

const MemoizedSearchResults = memo(({ 
    query,
    currentMessages,
    currentChat,
    user,
    formatTime,
    onMessageSelect,
    isSearching
}) => {
    // Memoize the filtered and grouped messages
    const filteredAndGroupedMessages = useMemo(() => {
        if (!query || !currentMessages) return [];
        const searchQuery = query.toLowerCase();
        const filtered = currentMessages
            .filter(message => message.content.toLowerCase().includes(searchQuery))
            .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)); // Sort newest first
        return groupMessagesByDate(filtered);
    }, [query, currentMessages]);

    if (isSearching) {
        return (
            <div className="search-loading">
                <div className="loading-spinner"></div>
                <span>Searching...</span>
            </div>
        );
    }

    if (query && filteredAndGroupedMessages.length === 0) {
        return (
            <div className="no-results">
                No messages found for "{query}"
            </div>
        );
    }

    return query ? (
        <div className="search-section">
            <h3 className="search-section-title">
                Messages
                <span className="count">
                    {filteredAndGroupedMessages.reduce((total, group) => total + group.messages.length, 0)} results
                </span>
            </h3>
            {filteredAndGroupedMessages.map((group) => (
                <MessageResultGroup
                    key={group.timestamp}
                    date={group.date}
                    messages={group.messages}
                    user={user}
                    formatTime={formatTime}
                    onSelect={onMessageSelect}
                />
            ))}
        </div>
    ) : null;
});

// MemoizedGlobalSearchResults.js
const MemoizedGlobalSearchResults = memo(({
    query,
    contacts,
    onContactSelect,
    globalSearchResults,
    isSearching,
    user,
    formatTime,
    onMessageSelect
}) => {
    // Memoize filtered contacts
    const filteredContacts = useMemo(() => {
        if (!query) return [];
        const searchQuery = query.toLowerCase();
        return contacts.filter(contact => 
            contact.username.toLowerCase().includes(searchQuery)
        );
    }, [query, contacts]);

    // Group messages by date
    const groupedMessages = useMemo(() => {
        if (!globalSearchResults.messages) return [];
        return groupMessagesByDate(
            // Sort messages by date (newest first)
            [...globalSearchResults.messages].sort((a, b) => 
                new Date(b.timestamp) - new Date(a.timestamp)
            )
        );
    }, [globalSearchResults.messages]);

    if (isSearching) {
        return (
            <div className="search-loading">
                <div className="loading-spinner"></div>
                <span>Searching...</span>
            </div>
        );
    }

    if (query && 
        filteredContacts.length === 0 && 
        groupedMessages.length === 0) {
        return (
            <div className="no-results">
                No results found for "{query}"
            </div>
        );
    }

    return query ? (
        <>
            {filteredContacts.length > 0 && (
                <div className="search-section">
                    <h3 className="search-section-title">
                        Chats
                        <span className="count">{filteredContacts.length} results</span>
                    </h3>
                    {filteredContacts.map((contact) => (
                        <div
                            key={contact.id}
                            className="search-result-item"
                            onClick={() => onContactSelect(contact)}
                        >
                            <div className="search-result-name">
                                {contact.username}
                            </div>
                        </div>
                    ))}
                </div>
            )}
            
            {groupedMessages.length > 0 && (
                <div className="search-section">
                    <h3 className="search-section-title">
                        Messages
                        <span className="count">
                            {globalSearchResults.messages.length} results
                        </span>
                    </h3>
                    {groupedMessages.map((group) => (
                        <div key={group.timestamp} className="search-result-group">
                            <div className="search-result-date">{group.date}</div>
                            {group.messages.map((message) => (
                                <div
                                    key={message.id}
                                    className="search-result-item"
                                    onClick={() => onMessageSelect(message)}
                                >
                                    <div className="search-result-chat">
                                        {message.chat_name}
                                    </div>
                                    <div className="search-result-content">
                                        {message.content}
                                    </div>
                                    <div className="search-result-info">
                                        <span>
                                            {message.sender_id === user?.id ? 'You' : message.sender_name}
                                        </span>
                                        <span>{formatTime(message.timestamp)}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            )}
        </>
    ) : null;
});

export { MemoizedSearchResults, MemoizedGlobalSearchResults };