import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import Logger from '../services/logger';
import { clearUserMessageCache, clearIndexedDBMessageCache } from '../utils/messageCache';
import {
    ArrowLeftIcon,
    ArrowRightIcon,
    ChatBubbleLeftIcon,
    DocumentIcon,
    PhotoIcon,
    FilmIcon,
    MicrophoneIcon,
    EyeIcon,
    EyeSlashIcon
} from '@heroicons/react/24/outline';

const StatItem = React.memo(({ Icon, label, value }) => (
    <div className="flex items-center space-x-3 text-gray-600 dark:text-gray-400">
        <Icon className="h-5 w-5" />
        <span>{label}:</span>
        <span className="font-medium">{value}</span>
    </div>
));

const RestoreAccountPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    // Get data from location state
    const { deletedUser, registrationData } = location.state || {};

    // Redirect if no data
    if (!deletedUser || !registrationData) {
        navigate('/register');
        return null;
    }

    const handleForgotPassword = () => {
        navigate('/reset-password', {
            state: { email: registrationData.email }
        });
    };

    const handleRestore = async (restore) => {
        if (!deletedUser?.id || !registrationData?.email || !password) {
            toast.error('Please enter your password');
            setError('Please enter your password');
            return;
        }

        try {
            setError('');
            setIsLoading(true);

            // First verify the password
            const verifyResponse = await fetch('/api/verify-password', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    email: registrationData.email,
                    password
                }),
            });

            const verifyData = await verifyResponse.json();
            
            if (!verifyResponse.ok || !verifyData.success) {
                Logger.warn('Password verification failed', {
                    email: registrationData.email,
                    userId: deletedUser.id
                });
                throw new Error(verifyData.detail || 'Invalid password');
            }

            const response = await fetch('/api/restore-deleted-account', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    deleted_user_id: deletedUser.id,
                    restore_chats: restore,
                    email: registrationData.email,
                    username: registrationData.username || deletedUser.username,
                    password
                }),
            });

            if (!response.ok) {
                const text = await response.text();
                try {
                    const data = JSON.parse(text);
                    throw new Error(data.detail || 'Error handling deleted account');
                } catch (parseError) {
                    throw new Error('Server error: ' + text);
                }
            }

            // If not restoring chats, clear all message caches
            if (!restore) {
                await clearUserMessageCache(deletedUser.id);
                await clearIndexedDBMessageCache();
                Logger.info('Cleared message cache for fresh start', {
                    userId: deletedUser.id,
                    username: deletedUser.username
                });
            }
            
            const successMessage = restore 
                ? 'Account restored successfully with previous chats. Please login.' 
                : 'Account created successfully. Previous messages to other users are preserved.';
            
            Logger.info(`Account restoration completed: ${restore ? 'with chats' : 'fresh start'}`, {
                userId: deletedUser.id,
                username: deletedUser.username
            });
            
            toast.success(successMessage);
            navigate('/login', { state: { message: successMessage } });
        } catch (error) {
            const errorMessage = error.message || 'Error handling account';
            Logger.error('Account restoration failed', {
                userId: deletedUser.id,
                username: deletedUser.username,
                error: errorMessage
            });
            toast.error(errorMessage);
            setError(errorMessage);
        } finally {
            setIsLoading(false);
        }
    };

    const renderPasswordInput = () => (
        <div className="max-w-md mx-auto mt-8 space-y-4">
            <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Password
                </label>
                <div className="mt-1 relative">
                    <input
                        id="password"
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm dark:bg-gray-800 dark:text-white"
                        placeholder="Enter your password"
                    />
                    <button
                        type="button"
                        className="absolute inset-y-0 right-0 pr-3 flex items-center"
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? (
                            <EyeSlashIcon className="h-5 w-5 text-gray-400" />
                        ) : (
                            <EyeIcon className="h-5 w-5 text-gray-400" />
                        )}
                    </button>
                </div>
            </div>

            <div className="text-right">
                <button
                    type="button"
                    onClick={handleForgotPassword}
                    className="text-sm font-medium text-blue-600 dark:text-blue-400 hover:text-blue-500"
                >
                    Forgot password?
                </button>
            </div>
        </div>
    );

    const renderStep1 = () => (
        <div className="space-y-8">
            <div className="text-center">
                <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Previous Account Found</h2>
                <p className="mt-2 text-gray-600 dark:text-gray-400">
                    We found a previously deleted account with this email. Enter your password to continue.
                </p>
            </div>

            {renderPasswordInput()}

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg space-y-6">
                    <div className="space-y-4">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white flex items-center">
                            <ArrowLeftIcon className="h-5 w-5 mr-2" />
                            Restore Previous Chats
                        </h3>
                        <p className="text-gray-600 dark:text-gray-400">
                            Restore your account with all your previous chats and messages.
                        </p>
                        <div className="space-y-2">
                            <StatItem Icon={ChatBubbleLeftIcon} label="Text Messages" value={deletedUser.storage_breakdown?.text?.count || 0} />
                            <StatItem Icon={PhotoIcon} label="Photos" value={deletedUser.storage_breakdown?.image?.count || 0} />
                            <StatItem Icon={FilmIcon} label="Videos" value={deletedUser.storage_breakdown?.video?.count || 0} />
                            <StatItem Icon={MicrophoneIcon} label="Voice Messages" value={deletedUser.storage_breakdown?.audio?.count || 0} />
                            <StatItem Icon={DocumentIcon} label="Documents" value={deletedUser.storage_breakdown?.document?.count || 0} />
                        </div>
                        <button
                            onClick={() => handleRestore(true)}
                            className="w-full bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors duration-200"
                        >
                            Restore Previous Chats
                        </button>
                    </div>
                </div>

                <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg space-y-6">
                    <div className="space-y-4">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white flex items-center">
                            <ArrowRightIcon className="h-5 w-5 mr-2" />
                            Start Fresh
                        </h3>
                        <p className="text-gray-600 dark:text-gray-400">
                            Create a new account while preserving messages sent to other users.
                        </p>
                        <div className="space-y-2">
                            <p className="text-sm text-gray-500 dark:text-gray-400">
                                • Your previous chats will be permanently deleted
                            </p>
                            <p className="text-sm text-gray-500 dark:text-gray-400">
                                • Messages you sent to others will show as "Deleted User"
                            </p>
                            <p className="text-sm text-gray-500 dark:text-gray-400">
                                • You'll start with a clean chat history
                            </p>
                        </div>
                        <button
                            onClick={() => handleRestore(false)}
                            className="w-full bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-white px-4 py-2 rounded-lg hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors duration-200"
                        >
                            Start Fresh
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <div className="min-h-screen bg-gray-50 dark:bg-gray-900 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto">
                {/* Header */}
                <div className="text-center">
                    <h1 className="text-3xl font-bold text-gray-900 dark:text-white">
                        Restore Account
                    </h1>
                    <p className="mt-2 text-gray-600 dark:text-gray-400">
                        Choose how you'd like to proceed with your account
                    </p>
                </div>

                {/* Content */}
                {step === 1 ? renderStep1() : null}

                {/* Error message */}
                {error && (
                    <div className="mt-4 text-red-600 dark:text-red-400 text-sm text-center">
                        {error}
                    </div>
                )}

                {/* Loading Overlay */}
                {isLoading && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default RestoreAccountPage;
