// ReactionPickerComponent.js
import React, { memo, useState, useEffect, useRef } from 'react';

const COMMON_EMOJIS = ["👍", "❤️", "😂", "😮", "😢", "🙏", "🎉", "💯"];

const ReactionPicker = memo(({ messageId, onSelect, onClose, parentRef }) => {
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const pickerRef = useRef(null);

    useEffect(() => {
        if (parentRef.current && pickerRef.current) {
            const parentRect = parentRef.current.getBoundingClientRect();
            const pickerRect = pickerRef.current.getBoundingClientRect();
            
            // Calculate position to show above the message
            let top = -pickerRect.height - 10;
            let left = 0;

            // Adjust if would go off screen
            if (parentRect.top + top < 0) {
                top = parentRect.height + 10; // Show below instead
            }

            // Center horizontally but ensure it stays within viewport
            const centerOffset = (parentRect.width - pickerRect.width) / 2;
            left = Math.max(0, centerOffset);

            setPosition({ top, left });
        }
    }, [parentRef]);

    return (
        <div 
            ref={pickerRef}
            className="reaction-picker"
            onClick={e => e.stopPropagation()}
        >
            {COMMON_EMOJIS.map(emoji => (
                <button
                    key={emoji}
                    className="reaction-button"
                    onClick={() => {
                        onSelect(emoji, messageId);
                        onClose();
                    }}
                    style={{
                        border: 'none',
                        background: 'none',
                        padding: '4px 8px',
                        cursor: 'pointer',
                        borderRadius: '4px',
                        fontSize: '20px',
                        transition: 'transform 0.1s ease',
                        ':hover': {
                            transform: 'scale(1.2)',
                            background: 'rgba(0,0,0,0.05)'
                        }
                    }}
                >
                    {emoji}
                </button>
            ))}
        </div>
    );
});

export default ReactionPicker;