import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Logger from '../services/logger';
import {
  UserIcon, ChatBubbleLeftIcon, ServerIcon, ArrowRightOnRectangleIcon,
  MagnifyingGlassIcon, ArrowPathIcon, EllipsisVerticalIcon, PhotoIcon,
  VideoCameraIcon, DocumentIcon, MicrophoneIcon, DocumentTextIcon,
  SunIcon, MoonIcon, LockClosedIcon, NoSymbolIcon, ClockIcon,
  CloudArrowDownIcon, XMarkIcon, DocumentDuplicateIcon, TrashIcon
} from '@heroicons/react/24/outline';
import { useTheme } from '../contexts/ThemeContext';
import { Transition } from '@headlessui/react';
import { toast } from 'react-hot-toast';
import Notifications from './Notifications';
import SettingsMenu from './SettingsMenu';
import axios from 'axios';
import { formatBytes, formatDate } from '../utils/formatters';

const StatsCard = ({ title, value, icon: Icon, color }) => (
  <div className={`p-4 rounded-xl bg-white dark:bg-gray-800 shadow-lg border border-gray-200 dark:border-gray-700 
    transform transition-all duration-300 hover:scale-[1.02] hover:shadow-xl`}>
    <div className="flex items-center">
      <Icon className={`w-8 h-8 ${color} mr-3`} />
      <div>
        <p className="text-xs text-gray-500 dark:text-gray-400">{title}</p>
        <h3 className="text-lg font-bold text-gray-900 dark:text-white">{value}</h3>
      </div>
    </div>
  </div>
);

const UserOnlineStatusDot = ({ onlineStatus, lastSeen }) => {
  let color = 'bg-gray-500';
  let statusText = 'Offline';
  
  if (onlineStatus) {
    color = 'bg-green-500';
    statusText = 'Online';
  } else if (lastSeen && new Date(lastSeen) > new Date(Date.now() - 5 * 60 * 1000)) {
    color = 'bg-yellow-500';
    statusText = 'Away';
  }
  
  return (
    <div className="flex items-center gap-2">
      <div className={`w-2.5 h-2.5 rounded-full ${color} animate-pulse`} />
      <span className="text-sm text-gray-500 dark:text-gray-400">{statusText}</span>
    </div>
  );
};

const StorageBreakdownDialog = ({ open, onClose, storageByType, totalStorage }) => {
  if (!open) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={onClose} />
        
        <div className="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6">
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white" id="modal-title">
                  Storage Breakdown
                </h3>
                <div className="mt-4 space-y-4">
                  {[
                    { type: 'text', icon: DocumentTextIcon, label: 'Text Messages', color: 'text-blue-500' },
                    { type: 'images', icon: PhotoIcon, label: 'Images', color: 'text-green-500' },
                    { type: 'videos', icon: VideoCameraIcon, label: 'Videos', color: 'text-red-500' },
                    { type: 'files', icon: DocumentIcon, label: 'Files', color: 'text-orange-500' },
                    { type: 'voice', icon: MicrophoneIcon, label: 'Voice Messages', color: 'text-purple-500' }
                  ].map((item) => (
                    <div key={item.type} className="flex items-center justify-between p-3 rounded-lg bg-gray-50 dark:bg-gray-700">
                      <div className="flex items-center space-x-3">
                        <item.icon className={`w-6 h-6 ${item.color}`} />
                        <div>
                          <p className="font-medium text-gray-900 dark:text-white">{item.label}</p>
                          <p className="text-sm text-gray-500 dark:text-gray-400">
                            {formatBytes(storageByType?.[item.type] || 0, 2)}
                          </p>
                        </div>
                      </div>
                      <span className="text-sm text-gray-500 dark:text-gray-400">
                        {totalStorage > 0 ? ((storageByType?.[item.type] || 0) / totalStorage * 100).toFixed(1) : '0'}%
                      </span>
                    </div>
                  ))}
                </div>
                <div className="mt-6 p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
                  <p className="text-sm font-medium text-gray-900 dark:text-white">Total Storage Used</p>
                  <p className="text-2xl font-bold text-gray-900 dark:text-white mt-1">
                    {formatBytes(totalStorage, 2)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 dark:bg-gray-700 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              onClick={onClose}
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const UserStorageDialog = ({ user, onClose }) => {
  if (!user) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={onClose} />
        
        <div className="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                Storage Details - {user.username}
              </h3>
              <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
                <XMarkIcon className="h-6 w-6" />
              </button>
            </div>

            {/* Total Storage Summary */}
            <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-4 mb-4">
              <p className="text-sm font-medium text-gray-900 dark:text-white mb-2">
                Total Storage Usage
              </p>
              <div className="flex items-center mb-2">
                <div className="flex-grow mr-2">
                  <div className="w-full bg-gray-200 dark:bg-gray-600 rounded-full h-2">
                    <div
                      className="bg-blue-500 h-2 rounded-full"
                      style={{ width: `${(user.storage?.total / user.storage?.limit) * 100}%` }}
                    />
                  </div>
                </div>
                <span className="text-sm text-gray-500 dark:text-gray-400">
                  {((user.storage?.total / user.storage?.limit) * 100).toFixed(1)}%
                </span>
              </div>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                {formatBytes(user.storage?.total || 0, 2)} of {formatBytes(user.storage?.limit || 0, 2)} used
              </p>
            </div>

            {/* Storage Breakdown */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              {[
                { type: 'text', icon: DocumentTextIcon, label: 'Text Messages', color: 'text-blue-500' },
                { type: 'images', icon: PhotoIcon, label: 'Images', color: 'text-green-500' },
                { type: 'videos', icon: VideoCameraIcon, label: 'Videos', color: 'text-red-500' },
                { type: 'files', icon: DocumentIcon, label: 'Files', color: 'text-orange-500' },
                { type: 'voice', icon: MicrophoneIcon, label: 'Voice Messages', color: 'text-purple-500' }
              ].map((item) => (
                <div
                  key={item.type}
                  className="bg-gray-50 dark:bg-gray-700 rounded-lg p-4 flex items-start"
                >
                  <item.icon className={`h-5 w-5 ${item.color} mt-1 mr-3`} />
                  <div>
                    <p className="text-sm font-medium text-gray-900 dark:text-white">
                      {item.label}
                    </p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      {formatBytes(user.storage?.breakdown?.[item.type]?.size || 0, 2)}
                    </p>
                    <p className="text-xs text-gray-400 dark:text-gray-500">
                      {user.storage?.breakdown?.[item.type]?.count || 0} {item.type === 'text' || item.type === 'voice' ? 'messages' : 'files'}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const UserActionMenu = ({ isOpen, onClose, anchorEl, onAction, user }) => {
  if (!isOpen) return null;

  const menuStyle = {
    position: 'fixed',
    top: `${anchorEl?.getBoundingClientRect().bottom + window.scrollY}px`,
    left: `${anchorEl?.getBoundingClientRect().left - 150}px`,
    zIndex: 50,
  };

  const menuItems = [
    { icon: LockClosedIcon, label: 'Reset Password', action: 'reset' },
    { icon: NoSymbolIcon, label: user?.status === 'suspended' ? 'Activate User' : 'Suspend User', action: 'status' },
    { icon: TrashIcon, label: 'Delete User', action: 'delete' },
    { icon: XMarkIcon, label: 'Cancel Deletion', action: 'cancel-delete', show: user?.scheduled_deletion },
    { icon: CloudArrowDownIcon, label: 'Download Backup', action: 'backup' }
  ].filter(item => !item.hasOwnProperty('show') || item.show);

  return (
    <>
      <div className="fixed inset-0 z-30" onClick={onClose} />
      <div
        className="w-48 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5"
        style={menuStyle}
      >
        <div className="py-1">
          {menuItems.map((item) => (
            <button
              key={item.action}
              onClick={() => {
                onAction(item.action);
                onClose();
              }}
              className="w-full flex items-center px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              <item.icon className="h-5 w-5 mr-3 text-gray-400" />
              <span>{item.label}</span>
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

const ResetPasswordDialog = ({ open, onClose, user, onConfirm, tempPassword, error }) => {
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, [onClose]);

  const handleCopy = () => {
    navigator.clipboard.writeText(tempPassword);
    toast.success('Password copied to clipboard');
    onClose();
  };

  return (
    <Transition show={open} as={React.Fragment}>
      <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={onClose} />
        </Transition.Child>

        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div className="flex min-h-screen items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white dark:bg-gray-800 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6">
                <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                  Reset Password for {user?.username}
                </h3>
                <p className="text-sm text-gray-500 dark:text-gray-400 mb-4">
                  Are you sure you want to reset the password for this user?
                </p>
                {tempPassword && (
                  <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                      Temporary Password
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        readOnly
                        value={tempPassword}
                        className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white"
                      />
                      <button
                        onClick={handleCopy}
                        className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
                      >
                        <DocumentDuplicateIcon className="h-5 w-5" />
                      </button>
                    </div>
                  </div>
                )}
                {error && (
                  <p className="mt-2 text-sm text-red-600 dark:text-red-400" role="alert">
                    {error}
                  </p>
                )}
              </div>
              <div className="bg-gray-50 dark:bg-gray-700 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={onConfirm}
                  className="inline-flex w-full justify-center items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  {tempPassword ? (
                    <>
                      <ArrowPathIcon className="h-4 w-4 mr-2" />
                      Generate New
                    </>
                  ) : (
                    'Reset'
                  )}
                </button>
                {tempPassword && (
                  <button
                    type="button"
                    onClick={handleCopy}
                    className="mt-3 inline-flex w-full justify-center items-center rounded-md border border-gray-300 bg-white dark:bg-gray-800 px-4 py-2 text-base font-medium text-gray-700 dark:text-gray-300 shadow-sm hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                  >
                    <DocumentDuplicateIcon className="h-4 w-4 mr-2" />
                    Copy Password
                  </button>
                )}
                {!tempPassword && (
                  <button
                    type="button"
                    onClick={onClose}
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white dark:bg-gray-800 text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Cancel
                  </button>
                )}
              </div>
            </div>
          </div>
        </Transition.Child>
      </div>
    </Transition>
  );
};

const StatusDialog = ({ open, onClose, user, onConfirm, error }) => {
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, [onClose]);

  if (!open) return null;
  const newStatus = user?.status === 'suspended' ? 'active' : 'suspended';

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={onClose} />
        
        <div className="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6">
            <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
              Update User Status
            </h3>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Are you sure you want to mark user "{user?.username}" as {newStatus}?
            </p>
            {error && (
              <p className="mt-2 text-sm text-red-600 dark:text-red-400" role="alert">
                {error}
              </p>
            )}
          </div>
          <div className="bg-gray-50 dark:bg-gray-700 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              onClick={onConfirm}
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Update Status
            </button>
            <button
              type="button"
              onClick={onClose}
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white dark:bg-gray-800 text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const DeleteDialog = ({ open, onClose, user, onConfirm, error }) => {
    const [keepData, setKeepData] = useState(false);
    const [scheduleDays, setScheduleDays] = useState('');

    const handleDelete = () => {
        onConfirm(keepData, scheduleDays ? parseInt(scheduleDays) : null);
    };

    if (!open) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={onClose} />
                
                <div className="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6">
                        <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                            Delete User: {user?.username}
                        </h3>

                        <div className="space-y-4">
                            <div className="flex items-center space-x-3">
                                <input
                                    type="checkbox"
                                    id="keepData"
                                    checked={keepData}
                                    onChange={(e) => setKeepData(e.target.checked)}
                                    className="h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                                />
                                <label htmlFor="keepData" className="text-sm text-gray-700 dark:text-gray-300">
                                    Keep user data (messages will be preserved but marked as from "Deleted User")
                                </label>
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                                    Schedule deletion (Optional)
                                </label>
                                <input
                                    type="number"
                                    value={scheduleDays}
                                    onChange={(e) => setScheduleDays(e.target.value)}
                                    placeholder="Days until deletion"
                                    min="1"
                                    className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                />
                            </div>

                            {error && (
                                <p className="text-sm text-red-600 dark:text-red-400 mt-2">
                                    {error}
                                </p>
                            )}

                            <div className="mt-5 sm:mt-6 flex space-x-3">
                                <button
                                    onClick={handleDelete}
                                    className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
                                >
                                    Delete User
                                </button>
                                <button
                                    onClick={onClose}
                                    className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white dark:bg-gray-700 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const ScheduleDeletionDialog = ({ open, onClose, user, onConfirm, error }) => {
    const [gracePeriod, setGracePeriod] = useState(30);

    const handleConfirm = () => {
        onConfirm(true, gracePeriod); // Keep data and use grace period
        onClose();
    };

    if (!open) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={onClose} />
                
                <div className="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6">
                        <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
                            Schedule User Deletion: {user?.username}
                        </h3>
                        
                        <div className="mt-2">
                            <label htmlFor="gracePeriod" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                Days until deletion
                            </label>
                            <input
                                type="number"
                                id="gracePeriod"
                                value={gracePeriod}
                                onChange={(e) => setGracePeriod(parseInt(e.target.value) || 30)}
                                min="1"
                                className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            />
                        </div>

                        {error && (
                            <p className="mt-2 text-sm text-red-600 dark:text-red-400" role="alert">
                                {error}
                            </p>
                        )}

                        <div className="mt-5 sm:mt-6 flex space-x-3">
                            <button
                                onClick={handleConfirm}
                                className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
                            >
                                Schedule Deletion
                            </button>
                            <button
                                onClick={onClose}
                                className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white dark:bg-gray-700 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [stats, setStats] = useState({
    totalUsers: 0,
    totalMessages: 0,
    totalActiveUsers: 0,
    totalOnlineUsers: 0,
    totalStorage: {
      total: 0,
      by_type: {
        text: 0,
        images: 0,
        videos: 0,
        files: 0,
        voice: 0
      }
    }
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [serverTime, setServerTime] = useState('');
  const [storageBreakdownOpen, setStorageBreakdownOpen] = useState(false);
  const [selectedStorageUser, setSelectedStorageUser] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const { theme, toggleTheme } = useTheme();
  const navigate = useNavigate();
  const wsRef = useRef(null);
  const [resetDialogOpen, setResetDialogOpen] = useState(false);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [scheduleDialogOpen, setScheduleDialogOpen] = useState(false);
  const [tempPassword, setTempPassword] = useState('');
  const [actionError, setActionError] = useState(null);
  const [selectedUserForSettings, setSelectedUserForSettings] = useState(null);
  const [wsStatus, setWsStatus] = useState('disconnected');
  const reconnectAttempts = useRef(0);
  const maxReconnectAttempts = 3;
  const reconnectTimeoutRef = useRef(null);

  const connectWebSocket = useCallback(() => {
    if (reconnectAttempts.current >= maxReconnectAttempts) {
      setWsStatus('failed');
      Logger.error('Max reconnection attempts reached', { source: 'FRONTEND' });
      toast.error('Connection lost. Click to reconnect.', {
        duration: 0,  // Stay until clicked
        onClick: () => {
          reconnectAttempts.current = 0;
          connectWebSocket();
        }
      });
      return;
    }

    const token = localStorage.getItem('adminToken');
    if (!token) {
      Logger.warn('No admin token found, redirecting to login', { source: 'FRONTEND' });
      navigate('/admin/login');
      return;
    }

    try {
      const wsProtocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
      const wsUrl = `${wsProtocol}//${window.location.host}/ws/admin/Bearer ${token}`;
      wsRef.current = new WebSocket(wsUrl);

      wsRef.current.onopen = () => {
        Logger.info('WebSocket connected', { source: 'FRONTEND' });
        setError(null);
        setWsStatus('connected');
        reconnectAttempts.current = 0;
        setLoading(false);
      };

      wsRef.current.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          if (data.type === "admin_data_update") {
            setUsers(data.users);
            setStats(data.stats);
            setServerTime(new Date(data.timestamp).toLocaleString('en-GB', { timeZone: 'Asia/Dubai', hour12: true }));
            setLoading(false);
          }
        } catch (err) {
          Logger.error('Error processing WebSocket message', { error: err, source: 'FRONTEND' });
        }
      };

      wsRef.current.onclose = () => {
        Logger.warn('WebSocket disconnected', { source: 'FRONTEND' });
        setWsStatus('disconnected');
        
        reconnectAttempts.current += 1;
        if (reconnectAttempts.current < maxReconnectAttempts) {
          if (reconnectTimeoutRef.current) {
            clearTimeout(reconnectTimeoutRef.current);
          }
          reconnectTimeoutRef.current = setTimeout(() => {
            connectWebSocket();
          }, 5000); // 5 second delay between reconnection attempts
        }
      };

      // Start sending pings to get updates
      const pingInterval = setInterval(() => {
        if (wsRef.current?.readyState === WebSocket.OPEN) {
          wsRef.current.send(JSON.stringify({ type: 'ping' }));
        }
      }, 1000);

      return () => clearInterval(pingInterval);

    } catch (err) {
      Logger.error('Error creating WebSocket connection', { error: err, source: 'FRONTEND' });
      setError('Failed to establish WebSocket connection');
    }
  }, [navigate]);

  useEffect(() => {
    connectWebSocket();
    return () => {
      if (wsRef.current) {
        wsRef.current.close();
      }
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
    };
  }, [connectWebSocket]);

  const renderConnectionStatus = () => {
    switch (wsStatus) {
      case 'connected':
        return (
          <span className="text-green-500 text-sm flex items-center">
            <div className="w-2 h-2 rounded-full bg-green-500 mr-2 animate-pulse" />
            Connected
          </span>
        );
      case 'disconnected':
        return (
          <span className="text-yellow-500 text-sm flex items-center">
            <div className="w-2 h-2 rounded-full bg-yellow-500 mr-2" />
            Reconnecting...
          </span>
        );
      case 'failed':
        return (
          <button
            onClick={() => {
              reconnectAttempts.current = 0;
              connectWebSocket();
            }}
            className="text-red-500 text-sm flex items-center hover:text-red-600"
          >
            <div className="w-2 h-2 rounded-full bg-red-500 mr-2" />
            Click to reconnect
          </button>
        );
      default:
        return null;
    }
  };

  const handleResetPassword = async () => {
    if (!selectedUser?.id) {
      toast.error('User not found', {
        duration: 3000,
        position: 'top-right',
      });
      return;
    }

    try {
      Logger.info('Attempting to reset user password', { 
        username: selectedUser.username, 
        userId: selectedUser.id,
        source: 'FRONTEND'
      });
      
      const response = await axios.post(
        `/api/admin/reset-user-password/${selectedUser.id}`,
        {},
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('adminToken')}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      // With axios, the response data is in response.data
      setTempPassword(response.data.temporary_password);
      toast.success('Password reset initiated');
      
      Logger.info('Reset user password is initiated', { 
        username: selectedUser.username, 
        userId: selectedUser.id,
        source: 'FRONTEND'
      });
    } catch (error) {
      Logger.error('Failed to initiate password reset', { 
        error,
        username: selectedUser.username,
        userId: selectedUser.id,
        source: 'FRONTEND'
      });
      toast.error(error.response?.data?.detail || 'Failed to initiate password reset');
    }
  };

  const handleStatusChange = async () => {
    if (!selectedUser?.id) {
        toast.error('User not found');
        return;
    }

    try {
        const newStatus = selectedUser.status === 'suspended' ? 'active' : 'suspended';
        Logger.info('Updating user status', { 
            username: selectedUser.username, 
            userId: selectedUser.id,
            newStatus,
            source: 'FRONTEND'
        });
        
        await axios.put(`/api/admin/users/${selectedUser.id}/status`, { status: newStatus }, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('adminToken')}`,
                'Content-Type': 'application/json'
            }
        });
        
        toast.success(`User ${newStatus === 'active' ? 'activated' : 'suspended'} successfully`);
        setStatusDialogOpen(false);
        Logger.info('Successfully updated user status', { 
            username: selectedUser.username, 
            userId: selectedUser.id,
            status: newStatus,
            source: 'FRONTEND'
        });
    } catch (error) {
        Logger.error('Failed to update user status', { 
            error,
            username: selectedUser.username,
            userId: selectedUser.id,
            source: 'FRONTEND'
        });
        toast.error(error.message || 'Failed to update user status');
    }
  };

  const handleCancelDeletion = async () => {
    if (!selectedUser?.id) {
      toast.error('User not found');
      return;
    }

    try {
      Logger.info('Cancelling scheduled deletion', { 
        username: selectedUser.username, 
        userId: selectedUser.id,
        source: 'FRONTEND'
      });
      const response = await axios.post(`/api/admin/users/${selectedUser.id}/cancel-deletion`, {}, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('adminToken')}`,
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) throw new Error('Failed to cancel deletion');
      toast.success('Scheduled deletion cancelled successfully');
      Logger.info('Successfully cancelled scheduled deletion', { 
        username: selectedUser.username, 
        userId: selectedUser.id,
        source: 'FRONTEND'
      });
    } catch (error) {
      Logger.error('Failed to cancel deletion', { 
        error,
        username: selectedUser.username,
        userId: selectedUser.id,
        source: 'FRONTEND'
      });
      toast.error(error.message || 'Failed to cancel deletion');
    }
  };

  const handleDownloadBackup = async () => {
    if (!selectedUser?.id) {
      toast.error('User not found');
      return;
    }

    try {
      Logger.info('Downloading user backup', { 
        username: selectedUser.username, 
        userId: selectedUser.id,
        source: 'FRONTEND'
      });
      const response = await fetch(`/api/admin/users/${selectedUser.id}/backup`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('adminToken')}`
        }
      });
      if (!response.ok) throw new Error('Failed to download backup');
      const data = await response.json();
      const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `user_${selectedUser.id}_backup.json`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      toast.success('Backup downloaded successfully');
      Logger.info('Successfully downloaded user backup', { 
        username: selectedUser.username, 
        userId: selectedUser.id,
        source: 'FRONTEND'
      });
    } catch (error) {
      Logger.error('Failed to download backup', { 
        error,
        username: selectedUser.username,
        userId: selectedUser.id,
        source: 'FRONTEND'
      });
      toast.error(error.message || 'Failed to download backup');
    }
  };

  const handleImmediateDelete = async (keepData, scheduleDays) => {
    try {
      const token = localStorage.getItem('adminToken');
      
      Logger.info('Admin attempting to delete user', {
        userId: selectedUser.id,
        keepData,
        scheduleDays,
        source: 'ADMIN'
      });

      const payload = {
        delete_request: {  // Nest under delete_request to match Pydantic model
            keep_data: keepData ? 1 : 0
        },
        schedule_days: scheduleDays ? parseInt(scheduleDays) : null
      };

      await axios.delete(
        `/api/admin/users/${selectedUser.id}/delete`, 
        {
          data: payload,
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );

      Logger.info('Admin successfully deleted user', {
        userId: selectedUser.id,
        keepData,
        scheduleDays,
        source: 'ADMIN'
      });

      toast.success('User deleted successfully');
      setSelectedUser(null);
      setDeleteDialogOpen(false);
      // WebSocket will automatically update the user list
    } catch (error) {
      Logger.error('Admin failed to delete user:', {
        error: error.message,
        userId: selectedUser?.id,
        keepData,
        scheduleDays,
        source: 'ADMIN'
      });
      setError(error.response?.data?.detail || 'Failed to delete user');
      toast.error(error.response?.data?.detail || 'Failed to delete user');
    }
  };

  const handleActionClick = (action) => {
    if (!selectedUser?.id) {
      toast.error('User not found');
      return;
    }

    setActionError(null);
    switch (action) {
      case 'reset':
        setResetDialogOpen(true);
        break;
      case 'status':
        setStatusDialogOpen(true);
        break;
      case 'delete':
        setDeleteDialogOpen(true);
        break;
      case 'cancel-delete':
        handleCancelDeletion();
        break;
      case 'backup':
        handleDownloadBackup();
        break;
      default:
        break;
    }
    setMenuAnchorEl(null);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen p-4">
        <p className="text-red-500 text-lg mb-4">{error}</p>
        <button
          onClick={() => window.location.reload()}
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 flex flex-col h-screen overflow-hidden">
      <Notifications />
      {/* Header - Fixed height */}
      <header className="bg-white dark:bg-gray-800 shadow h-14 flex-none">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-3">
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Admin Dashboard</h1>
            <div className="flex items-center space-x-4">
              <div className="flex items-center space-x-2">
                <ClockIcon className="h-5 w-5 text-gray-500 dark:text-gray-400" />
                <span className="text-sm text-gray-500 dark:text-gray-400">
                  Server Time: {serverTime}
                </span>
              </div>
              {renderConnectionStatus()}
              <button
                onClick={toggleTheme}
                className="p-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
              >
                {theme === 'dark' ? (
                  <SunIcon className="h-5 w-5" />
                ) : (
                  <MoonIcon className="h-5 w-5" />
                )}
              </button>
              <button
                onClick={() => window.location.reload()}
                className="p-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
              >
                <ArrowPathIcon className="h-5 w-5" />
              </button>
              <button
                onClick={() => {
                  localStorage.removeItem('adminToken');
                  navigate('/login');
                }}
                className="p-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
              >
                <ArrowRightOnRectangleIcon className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      </header>

      {/* Main Content - Calculate exact height */}
      <main className="flex-1 w-full mx-auto p-4 overflow-hidden" style={{ height: 'calc(100vh - 56px)' }}>
        {/* Stats Grid - Fixed height */}
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4 mb-4 h-[80px]">
          <StatsCard
            title="Total Users"
            value={stats.totalUsers}
            icon={UserIcon}
            color="text-blue-500"
          />
          <StatsCard
            title="Total Active Users"
            value={stats.totalActiveUsers}
            icon={UserIcon}
            color="text-green-500"
          />
          <StatsCard
            title="Online Users"
            value={stats.totalOnlineUsers}
            icon={UserIcon}
            color="text-yellow-500"
          />
          <StatsCard
            title="Total Messages"
            value={stats.totalMessages}
            icon={ChatBubbleLeftIcon}
            color="text-green-500"
          />
          <div onClick={() => setStorageBreakdownOpen(true)} className="cursor-pointer">
            <StatsCard
              title="Total Storage"
              value={formatBytes(stats.totalStorage.total, 2)}
              icon={ServerIcon}
              color="text-pink-500"
            />
          </div>
        </div>

        {/* Users Table Container - Exact height calculation */}
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow overflow-hidden" 
          style={{ height: 'calc(100vh - 160px)' }}>
          {/* Search Bar - Fixed height */}
          <div className="p-4 border-b border-gray-200 dark:border-gray-700 h-[72px]">
            <div className="relative">
              <input
                type="text"
                placeholder="Search users..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 
                  text-gray-900 dark:text-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
              <MagnifyingGlassIcon className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
          </div>

          {/* Table Container - Exact height calculation */}
          <div className="overflow-auto" style={{ height: 'calc(100vh - 340px)' }}>
            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
              <thead className="bg-gray-50 dark:bg-gray-700 sticky top-0 z-10">
                <tr>
                  {[
                    "#", "Username", "Email", "Status", "Last Seen", "Last Password Changed",
                    "Total Messages", "Messages Sent", "Messages Received", "Storage Used",
                    "Storage Limit", "User Status", "Actions"
                  ].map((header) => (
                    <th
                      key={header}
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider whitespace-nowrap"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                {users
                  .filter(user => 
                    user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    user.email.toLowerCase().includes(searchTerm.toLowerCase())
                  )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((user, index) => (
                    <tr key={user.id} className="hover:bg-gray-50 dark:hover:bg-gray-700">
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                        {index + 1}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900 dark:text-white">
                          {user.username}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-500 dark:text-gray-400">
                          {user.email}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <UserOnlineStatusDot status={user.online_status} lastSeen={user.last_seen} />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                        {formatDate(user.last_seen)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                        {formatDate(user.last_password_changed)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                        {user.total_user_messages}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                        {user.sent_messages}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                        {user.received_messages}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div 
                          className="w-full cursor-pointer hover:bg-gray-50 dark:hover:bg-gray-700 p-2 rounded-lg"
                          onClick={() => user?.id && setSelectedStorageUser(user)}
                        >
                          <div className="flex justify-between items-center mb-1">
                            <span className="text-sm text-gray-500 dark:text-gray-400">
                              {formatBytes(user.storage?.total || 0, 2)}
                            </span>
                            <span className="text-sm text-gray-500 dark:text-gray-400 ml-4">
                              {((user.storage?.total / user.storage?.limit) * 100).toFixed(1)}%
                            </span>
                          </div>
                          <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2">
                            <div
                              className="bg-blue-500 h-2 rounded-full"
                              style={{ width: `${(user.storage?.total / user.storage?.limit) * 100}%` }}
                            />
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                        {formatBytes(user.storage?.limit || 0, 2)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full
                          ${user.status === 'active' ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200' :
                            user.status === 'suspended' ? 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200' :
                            'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-200'}`}>
                          {user.status}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <button 
                          className="text-blue-600 hover:text-blue-900 dark:text-blue-400 dark:hover:text-blue-300"
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedUser(user);
                            setMenuAnchorEl(e.currentTarget);
                          }}
                        >
                          <EllipsisVerticalIcon className="h-5 w-5" />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          {/* Pagination - Fixed height */}
          <div className="bg-white dark:bg-gray-800 px-4 py-3 border-t border-gray-200 dark:border-gray-700 h-[48px]">
            <div className="flex-1 flex justify-between sm:hidden">
              <button
                onClick={() => setPage(Math.max(0, page - 1))}
                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                Previous
              </button>
              <button
                onClick={() => setPage(Math.min(Math.ceil(users.length / rowsPerPage) - 1, page + 1))}
                className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
              >
                Next
              </button>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700 dark:text-gray-400">
                  Showing{' '}
                  <span className="font-medium">{page * rowsPerPage + 1}</span>
                  {' '}to{' '}
                  <span className="font-medium">
                    {Math.min((page + 1) * rowsPerPage, users.length)}
                  </span>
                  {' '}of{' '}
                  <span className="font-medium">{users.length}</span>
                  {' '}results
                </p>
              </div>
              <div>
                <select
                  value={rowsPerPage}
                  onChange={(e) => {
                    setRowsPerPage(Number(e.target.value));
                    setPage(0);
                  }}
                  className="mr-4 rounded-md border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-700 
                    text-gray-900 dark:text-white focus:ring-blue-500 focus:border-blue-500"
                >
                  {[10, 25, 50, 100].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
                <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
                  <button
                    onClick={() => setPage(Math.max(0, page - 1))}
                    className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    Previous
                  </button>
                  {[...Array(Math.min(5, Math.ceil(users.length / rowsPerPage)))].map((_, i) => (
                    <button
                      key={i}
                      onClick={() => setPage(i)}
                      className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium
                        ${page === i
                          ? 'z-10 bg-blue-50 border-blue-500 text-blue-600'
                          : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
                        }`}
                    >
                      {i + 1}
                    </button>
                  ))}
                  <button
                    onClick={() => setPage(Math.min(Math.ceil(users.length / rowsPerPage) - 1, page + 1))}
                    className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    Next
                  </button>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* Dialogs */}
      <StorageBreakdownDialog
        open={storageBreakdownOpen}
        onClose={() => setStorageBreakdownOpen(false)}
        storageByType={stats.totalStorage.by_type}
        totalStorage={stats.totalStorage.total}
      />

      <UserStorageDialog
        user={selectedStorageUser}
        onClose={() => setSelectedStorageUser(null)}
      />

      <UserActionMenu
        isOpen={Boolean(menuAnchorEl)}
        onClose={() => {
          setMenuAnchorEl(null);
        }}
        anchorEl={menuAnchorEl}
        onAction={handleActionClick}
        user={selectedUser}
      />

      <ResetPasswordDialog
        open={resetDialogOpen}
        onClose={() => {
          setResetDialogOpen(false);
          setTempPassword('');
          setActionError(null);
        }}
        user={selectedUser}
        onConfirm={handleResetPassword}
        tempPassword={tempPassword}
        error={actionError}
      />

      <StatusDialog
        open={statusDialogOpen}
        onClose={() => {
          setStatusDialogOpen(false);
          setActionError(null);
        }}
        user={selectedUser}
        onConfirm={handleStatusChange}
        error={actionError}
      />

      <DeleteDialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
          setActionError(null);
        }}
        user={selectedUser}
        onConfirm={handleImmediateDelete}
        error={actionError}
      />

      <ScheduleDeletionDialog
        open={scheduleDialogOpen}
        onClose={() => {
          setScheduleDialogOpen(false);
          setActionError(null);
        }}
        user={selectedUser}
        onConfirm={handleImmediateDelete}
        error={actionError}
      />

      {selectedUserForSettings && (
        <SettingsMenu
          onClose={() => setSelectedUserForSettings(null)}
          user={selectedUserForSettings}
          isAdmin={true}
        />
      )}
    </div>
  );
};

export default AdminDashboard; 