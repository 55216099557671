import React, { useState, useEffect, useRef, useCallback, useMemo, memo } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-hot-toast'
import Logger from './services/logger';
import './App.css';
import './styles/Auth.css';
import './styles/theme.css';
import { ThemeProvider, useTheme } from './contexts/ThemeContext';
import RegisterForm from './components/RegisterForm';
import LoginForm from './components/LoginForm';
import ResetPasswordForm from './components/ResetPasswordForm';
import MessageInput from './components/MessageInput';
import MessageSearchInput from './components/MessageSearchInput';
import GlobalSearchInput from './components/GlobalSearchInput';
import MemoizedMessage from './components/MemoizedMessage';
import TypingIndicator from './components/TypingIndicator';
import { MemoizedSearchResults, MemoizedGlobalSearchResults } from './components/MemoizedSearchResults';
import StableMessageList from './components/StableMessageList';
import ContactItem from './components/ContactItem';
import AdminLoginTailwind from './components/AdminLoginTailwind';
import AdminDashboardTailwind from './components/AdminDashboardTailwind';
import ChangePasswordForm from './components/ChangePasswordForm';
import SettingsMenu from './components/SettingsMenu';
import Notifications from './components/Notifications';
import RestoreAccountPage from './pages/RestoreAccountPage';

import { formatTime, formatTimestampToDate, formatLastSeen, sortMessages } from './components/UtilityFunc';

const API_URL = '/api';

function AppContent() {
    const navigate = useNavigate();
    const { theme, toggleTheme } = useTheme();
    const [user, setUser] = useState(null);
    const [showSettings, setShowSettings] = useState(false);
    const [page, setPage] = useState('login');
    const [contacts, setContacts] = useState([]);
    const [currentChat, setCurrentChat] = useState(null);
    const [onlineUsers, setOnlineUsers] = useState(new Set());
    const [allMessages, setAllMessages] = useState({});
    const [currentMessages, setCurrentMessages] = useState([]);
    const [error, setError] = useState('');
    const [unreadMessages, setUnreadMessages] = useState({});
    const [isAtBottom, setIsAtBottom] = useState(true);
    const [typingUsers, setTypingUsers] = useState(new Map());
    const [lastMessageTimestamps, setLastMessageTimestamps] = useState({});
    const [showScrollButton, setShowScrollButton] = useState(false);
    const [showChatBox, setShowChatBox] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [editingMessage, setEditingMessage] = useState(null);
    const [messageToDelete, setMessageToDelete] = useState(null);
    const [showReactionPicker, setShowReactionPicker] = useState(null);
    const [messageReactions, setMessageReactions] = useState({});
    const [messageSearchQuery, setMessageSearchQuery] = useState(''); // For message search
    const [showMessageSearch, setShowMessageSearch] = useState(false);
    const [globalSearchQuery, setGlobalSearchQuery] = useState('');
    const [globalSearchResults, setGlobalSearchResults] = useState({ chats: [], messages: [] });
    const [forwardingMessage, setForwardingMessage] = useState(null);
    const [showForwardDialog, setShowForwardDialog] = useState(false);
    const [isTypingMessage, setIsTypingMessage] = useState(false);
    const [isTypingMessageSearchBar, setIsTypingMessageSearchBar] = useState(false);
    const [isTypingGlobalSearchBar, setIsTypingGlobalSearchBar] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [messageCache, setMessageCache] = useState(new Map());
    const MAX_CACHED_CHATS = 5; // Maximum number of chats to keep in cache
    const [replyingTo, setReplyingTo] = useState(null);
    const [isForcePasswordChange, setIsForcePasswordChange] = useState(false);
    const [forcedPassChangeUser, setForcedPassChangeUser] = useState('null');
    const [resetToken, setResetToken] = useState(null);
    const [showAllUsers, setShowAllUsers] = useState(false);
    const [isTransitioning, setIsTransitioning] = useState(false);

    // Refs
    const websocket = useRef(null);
    const messagesContainerRef = useRef(null);
    const userScrollTimeoutRef = useRef(null);
    const typingTimeouts = useRef(new Map());
    const sendingRef = useRef(false);
    const verifyTimeoutRef = useRef(null);
    const currentChatIdRef = useRef(null);
    const messageHeights = useRef({});
    const listRef = useRef();

    // Message Status Component
    const MessageStatus = ({ message }) => {
        const SingleTickIcon = () => (
            <svg className="tick-icon" viewBox="0 0 12 12" fill="currentColor">
                <path d="M8.5 2.5l-.35-.3a.3.3 0 0 0-.4.05L4.2 6.7a.25.25 0 0 1-.38.03L2.1 5.1a.3.3 0 0 0-.4 0l-.32.33a.3.3 0 0 0 0 .4l2.5 2.4a.25.25 0 0 0 .38-.02L8.55 2.9a.3.3 0 0 0-.05-.4z"/>
            </svg>
        );

        const DoubleTickIcon = () => (
            <svg className="tick-icon" viewBox="0 0 12 12" fill="currentColor">
                <path d="M8.5 2.5l-.35-.3a.3.3 0 0 0-.4.05L4.2 6.7a.25.25 0 0 1-.38.03L2.1 5.1a.3.3 0 0 0-.4 0l-.32.33a.3.3 0 0 0 0 .4l2.5 2.4a.25.25 0 0 0 .38-.02L8.55 2.9a.3.3 0 0 0-.05-.4z"/>
                <path d="M11.5 2.5l-.35-.3a.3.3 0 0 0-.4.05L7.2 6.7a.25.25 0 0 1-.38.03l-.27-.25a.25.25 0 0 0-.38.02l-.28.37a.25.25 0 0 0 .02.38l1.45 1.15a.25.25 0 0 0 .38-.02l4.3-6a.3.3 0 0 0-.05-.4z"/>
            </svg>
        );

        return (
            <div className={`message-ticks ${message.read ? 'read' : message.delivered ? 'delivered' : 'sent'}`}>
                {message.read || message.delivered ? <DoubleTickIcon /> : <SingleTickIcon />}
            </div>
        );
    };

    const ScrollToBottomButton = ({ onClick, visible }) => {
        const [isVisible, setIsVisible] = useState(visible);
        
        useEffect(() => {
            let timeoutId;
            if (visible) {
                setIsVisible(true);
            } else {
                timeoutId = setTimeout(() => {
                    setIsVisible(false);
                }, 300); // Increased timeout for smoother transition
            }
            
            return () => {
                if (timeoutId) clearTimeout(timeoutId);
            };
        }, [visible]);
        
        if (!isVisible) return null;
        
        return (
            <button
                className={`scroll-to-bottom ${visible ? 'visible' : 'fade-out'}`}
                onClick={onClick}
                aria-label="Scroll to bottom"
            >
                ↓
            </button>
        );
    };

    const sendWebSocketMessage = (message) => {
        if (websocket.current?.readyState === WebSocket.OPEN) {
            websocket.current.send(JSON.stringify(message));
        }
    };

    const scrollToBottom = useCallback(() => {
        if (listRef.current && currentMessages.length > 0) {
            const lastIndex = currentMessages.length - 1;
            const buffer = 100; // Increased buffer for failproof

            // Reset list measurements before scrolling
            listRef.current.resetAfterIndex(0);

            // Use requestAnimationFrame to scroll to the last message with buffer
            requestAnimationFrame(() => {
                listRef.current.scrollToItem(lastIndex + buffer, 'end');
                setIsAtBottom(true);
                setShowScrollButton(false);
            });
        }
    }, [currentMessages.length]);

    // Scroll Handling
    const handleScroll = useCallback(({ scrollDirection, scrollOffset, scrollUpdateWasRequested }) => {
        if (scrollUpdateWasRequested) return;
        
        const containerHeight = messagesContainerRef.current?.clientHeight || 0;
        const totalHeight = currentMessages.reduce((acc, msg, index) => {
            const height = messageHeights.current[msg.id] || 40; // Use cached height or default
            return acc + height;
        }, 0);
        
        const isNearBottom = totalHeight - scrollOffset - containerHeight < 100;
        
        setIsAtBottom(isNearBottom);
        setShowScrollButton(!isNearBottom);
    }, [currentMessages]);
    
    const handleTypingStatus = useCallback((isTyping) => {
        if (currentChat?.id && websocket.current) {
            try {
                sendWebSocketMessage({
                    type: 'typing_status',
                    receiver_id: currentChat.id,
                    isTyping
                });
            } catch (error) {
                Logger.error('Failed to send typing status:', error);
            }
        }
    }, [currentChat]);

    const handleLogin = async (username, password) => {
        try {
            const response = await axios.post(`${API_URL}/login`, {
                username,
                password,
            });
            const { 
                access_token, 
                refresh_token,  // Add this
                must_change_password, 
                user_id, 
                username: responseUsername 
            } = response.data;
            
            if (must_change_password) {
                setIsForcePasswordChange(true);
                setForcedPassChangeUser({
                    id: user_id,
                    username: responseUsername
                });
                navigate('/change-password');
                return false; // Return false to indicate password change required
            } else {
                localStorage.setItem('token', access_token);
                localStorage.setItem('refresh_token', refresh_token);  // Save refresh token
                setUser({
                    id: user_id,
                    username: responseUsername
                });
                setError('');
                setPage('chat');
                fetchContacts();
                navigate('/chat');
                return true; // Return true to indicate success to LoginForm
            }
        } catch (error) {
            setError(error.response?.data?.detail || 'Login failed');
            throw error; // Throw error to be caught by LoginForm
        }
    };

    const handleLogout = useCallback(async () => {
        try {
            Logger.info('Logging out user');
            const token = localStorage.getItem('token');
            const refreshToken = localStorage.getItem('refresh_token');
            
            if (token) {
                await axios.post(`${API_URL}/logout`, 
                    { 
                        userId: user.id,
                        refresh_token: refreshToken  // Add refresh token to request
                    }, 
                    {
                        headers: { Authorization: `Bearer ${token}` }
                    }
                );
                setOnlineUsers(prevOnlineUsers => {
                    const newOnlineUsers = new Set(prevOnlineUsers);
                    newOnlineUsers.delete(user.id);
                    return newOnlineUsers;
                });
            }
        } catch (error) {
            Logger.error('Logout error:', error);
            toast.error('Logout failed');
        } finally {
            Logger.info('Logged out, cleaning up state and local storage');
            if (websocket.current) {
                websocket.current.close();
                websocket.current = null;
            }
            setUser(null);
            setContacts([]);
            setAllMessages({});
            setCurrentMessages([]);
            setCurrentChat(null);
            setUnreadMessages({});
            setPage('login');
            setGlobalSearchQuery('');
            setGlobalSearchResults({ chats: [], messages: [] });
            setMessageSearchQuery('');
            localStorage.removeItem('token');
            localStorage.removeItem('refresh_token');  // Also remove refresh token
        }
    }, [user?.id, setOnlineUsers, websocket]);

    // Add cleanup handlers for browser/tab close
    useEffect(() => {
        const handleBeforeUnload = async (event) => {
            if (user?.id) {
                const token = localStorage.getItem('token');
                const refreshToken = localStorage.getItem('refresh_token');
                if (token && refreshToken) {
                    try {
                        await axios.post(`${API_URL}/logout`, 
                            { 
                                userId: user.id,
                                refresh_token: refreshToken
                            }, 
                            {
                                headers: { Authorization: `Bearer ${token}` }
                            }
                        );
                    } catch (error) {
                        Logger.error('Error during cleanup logout:', error);
                    }
                }
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [user?.id]);

    // Update WebSocket error handling
    useEffect(() => {
        if (websocket.current) {
            websocket.current.onerror = async (error) => {
                Logger.error('WebSocket error:', error);
                // Attempt to reconnect instead of logging out
                if (websocket.current) {
                    websocket.current.close();
                }
            };

            websocket.current.onclose = async () => {
                Logger.info('WebSocket connection closed, attempting to reconnect...');
                // Attempt to reconnect after a delay
                setTimeout(() => {
                    if (user?.id) {
                        const token = localStorage.getItem('token');
                        if (token) {
                            connectWebSocket(token);
                        }
                    }
                }, 3000);
            };
        }
    }, [user?.id]);

    // API Calls
    const fetchMessages = useCallback(async (otherUserId) => {
        try {
            const token = localStorage.getItem('token');
            const lastTimestamp = lastMessageTimestamps[otherUserId];
            
            let url = `${API_URL}/messages/${otherUserId}`;
            if (lastTimestamp) {
                url += `?after=${lastTimestamp}`;
            }
    
            const response = await axios.get(url, {
                headers: { Authorization: `Bearer ${token}` }
            });
    
            const sortedMessages = sortMessages(response.data);
    
            if (sortedMessages.length > 0) {
                // Update messageReactions state with reactions from fetched messages
                const newReactions = {};
                sortedMessages.forEach(message => {
                    if (message.reactions && Object.keys(message.reactions).length > 0) {
                        newReactions[message.id] = message.reactions;
                    }
                });
                
                setMessageReactions(prev => ({
                    ...prev,
                    ...newReactions
                }));
    
                setAllMessages(prev => ({
                    ...prev,
                    [otherUserId]: sortMessages([...(prev[otherUserId] || []), ...sortedMessages])
                }));
    
                if (currentChat?.id === otherUserId) {
                    setCurrentMessages(prev => sortMessages([...prev, ...sortedMessages]));
                }
    
                // Update last message timestamp
                const latestMessageTime = Math.max(
                    ...sortedMessages.map(m => new Date(m.timestamp).getTime())
                );
                setLastMessageTimestamps(prev => ({
                    ...prev,
                    [otherUserId]: latestMessageTime
                }));
    
                Logger.info('Updated message reactions:', newReactions);
            }
    
            return sortedMessages;
        } catch (error) {
            Logger.error('Failed to fetch messages:', error);
            return [];
        }
    }, [lastMessageTimestamps, currentChat?.id, setMessageReactions, setAllMessages, setCurrentMessages, setLastMessageTimestamps]);

    const fetchContacts = useCallback(async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${API_URL}/users`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setContacts(response.data);

            // Only fetch messages for contacts if we don't already have them
            response.data.forEach(contact => {
                if (!allMessages[contact.id] || allMessages[contact.id].length === 0) {
                    fetchMessages(contact.id);
                }
            });
        } catch (error) {
            Logger.error('Failed to fetch contacts:', error);
            if (error.response?.status === 401) {
                handleLogout();
            }
        }
    }, [allMessages, fetchMessages, handleLogout]);

    // Handle delivery confirmation and update message status
    const updateMessageStatus = useCallback((messageData) => {
        Logger.info('Received message status update:', messageData);
        // Update message status in state
        setAllMessages(prev => {
            const updatedMessages = { ...prev };
            Object.keys(updatedMessages).forEach(chatId => {
                updatedMessages[chatId] = updatedMessages[chatId].map(msg => {
                    if (msg.id === messageData.message_id) {
                        return {
                            ...msg,
                            delivered: messageData.status === "delivered" || messageData.status === "read",
                            read: messageData.status === "read"
                        };
                    }
                    return msg;
                });
            });
            return updatedMessages;
        });
    
        // Update current messages if needed
        setCurrentMessages(prev =>
            prev.map(msg =>
                msg.id === messageData.message_id
                    ? { ...msg, delivered: messageData.status === "delivered" || messageData.status === "read", read: messageData.status === "read" }
                    : msg
            )
        );
    }, [setAllMessages, setCurrentMessages]);

    const sendRegularMessage = useCallback(async (content, chatId) => {
        const messageData = {
            type: 'new_send_message',
            content: content.trim(),
            receiver_id: chatId,
            temp_id: `temp-${Date.now()}`
        };
    
        sendWebSocketMessage(messageData);
    }, []);

    const sendReplyMessage = useCallback(async (content, replyToMessage) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(
                `/api/messages/${replyToMessage.id}/reply`,
                { 
                    content: content.trim(),
                    reply_to_id: replyToMessage.id 
                },
                { 
                    headers: { 
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            
            return response.data;
        } catch (error) {
            Logger.error('Failed to send reply:', error);
            throw error;
        }
    }, []);

    const handleReaction = useCallback(async (emoji, messageId) => {
      try {
          const token = localStorage.getItem('token');
          await axios.post(
              `${API_URL}/messages/${messageId}/reactions`,
              { emoji: emoji },  // Make sure emoji is sent as an object
              {
                  headers: { 
                      'Authorization': `Bearer ${token}`,
                      'Content-Type': 'application/json'
                  }
              }
          );
                    
      } catch (error) {
          Logger.error('Failed to add reaction:', error);
      }
    }, []);

    const handleEditMessage = useCallback(async (messageId, newContent) => {
        try {
            const token = localStorage.getItem('token');
            await axios.put(
                `${API_URL}/messages/${messageId}`,
                { content: newContent }, // Send content in the proper format
                {
                    headers: { 
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
            
            setEditingMessage(null);
                        
        } catch (error) {
            Logger.error('Failed to edit message:', error);
            // Optionally show error to user
            setEditingMessage(null); // Reset editing state on error
        }
    }, []);

    const handleDeleteMessage = useCallback(async (messageId, deleteForEveryone = false) => {
      try {
          const token = localStorage.getItem('token');
          await axios.delete(
              `${API_URL}/messages/${messageId}?delete_for_everyone=${deleteForEveryone}`,
              {
                  headers: { 
                      'Authorization': `Bearer ${token}`,
                  }
              }
          );
          
          if (deleteForEveryone) {
              // Update message in currentMessages
              setCurrentMessages(prevMessages => 
                  prevMessages.map(msg => {
                      if (msg.id === messageId) {
                          return {
                              ...msg,
                              is_deleted: true,
                              deleted_for_everyone: true,
                              display_content: 'This message was deleted',
                              display_file_path: null
                          };
                      }
                      return msg;
                  })
              );
              
              // Update message in allMessages
              setAllMessages(prev => {
                  const newMessages = { ...prev };
                  Object.keys(newMessages).forEach(chatId => {
                      newMessages[chatId] = newMessages[chatId].map(msg => {
                          if (msg.id === messageId) {
                              return {
                                  ...msg,
                                  is_deleted: true,
                                  deleted_for_everyone: true,
                                  display_content: 'This message was deleted',
                                  display_file_path: null
                              };
                          }
                          return msg;
                      });
                  });
                  return newMessages;
              });
              
              // Update message cache
              setMessageCache(prev => {
                  const newCache = new Map(prev);
                  newCache.forEach((messages, chatId) => {
                      newCache.set(chatId, messages.map(msg => {
                          if (msg.id === messageId) {
                              return {
                                  ...msg,
                                  is_deleted: true,
                                  deleted_for_everyone: true,
                                  display_content: 'This message was deleted',
                                  display_file_path: null
                              };
                          }
                          return msg;
                      }));
                  });
                  return newCache;
              });
          } else {
              // Remove message from currentMessages
              setCurrentMessages(prevMessages => 
                  prevMessages.filter(msg => msg.id !== messageId)
              );
              
              // Remove message from allMessages
              setAllMessages(prev => {
                  const newMessages = { ...prev };
                  Object.keys(newMessages).forEach(chatId => {
                      newMessages[chatId] = newMessages[chatId].filter(msg => msg.id !== messageId);
                  });
                  return newMessages;
              });
              
              // Remove message from cache
              setMessageCache(prev => {
                  const newCache = new Map(prev);
                  newCache.forEach((messages, chatId) => {
                      newCache.set(chatId, messages.filter(msg => msg.id !== messageId));
                  });
                  return newCache;
              });
          }
          
          // Clear message reactions for the deleted message
          setMessageReactions(prev => {
              const newReactions = { ...prev };
              delete newReactions[messageId];
              return newReactions;
          });
          
          setMessageToDelete(null);
          toast.success('Message deleted successfully');
          Logger.info('Message deleted', { messageId, deleteForEveryone });
                    
      } catch (error) {
          Logger.error('Failed to delete message:', error);
          setMessageToDelete(null);
          toast.error('Failed to delete message. Please try again.');
      }
    }, []);

    // In handleForwardMessage function:
    const handleForwardMessage = useCallback(async (forwardingMessage, receiverId) => {
      try {
            const token = localStorage.getItem('token');
            await axios.post(
                `${API_URL}/messages/forward`,
                {
                    message_id: forwardingMessage.id,
                    receiver_id: receiverId
                },
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );
    
          // Clear forwarding states
          setShowForwardDialog(false);
          setForwardingMessage(null);
  
      } catch (error) {
          Logger.error('Failed to forward message:', error);
      }
    }, []);

    const handleReply = useCallback((message) => {
        setReplyingTo(message);
    }, []);

    // Chat Selection
    const handleChatSelect = useCallback(async (contact) => {
        if (contact.id === currentChat?.id && showChatBox) {
            Logger.info('Same chat selected, skipping', { contactId: contact.id });
            return;
        }
        
        Logger.info('Selecting chat:', { 
            contact,
            contactId: contact.id,
            contactUsername: contact.username
        });
        
        const newChat = {
            id: contact.id,
            username: contact.username,
            last_seen: contact.last_seen
        };
        
        setCurrentChat(newChat);
        setShowChatBox(true);
        
        // Clear existing reactions for this chat
        setMessageReactions({});

        // Reset list ref measurements
        if (listRef.current) {
            listRef.current.resetAfterIndex(0);
        }

        // Try to get messages from cache first
        if (messageCache.has(contact.id)) {
            Logger.info('Loading messages from cache for:', contact.username);
            setCurrentMessages(messageCache.get(contact.id));
        } else {
            Logger.info('No messages found in cache for:', contact.username);   
            
            // If not in cache, get from allMessages
            const existingMessages = allMessages[contact.id] || [];
            setCurrentMessages(sortMessages(existingMessages));
            
            // Initialize reactions from existing messages
            const existingReactions = {};
            existingMessages.forEach(message => {
                if (message.reactions && Object.keys(message.reactions).length > 0) {
                    existingReactions[message.id] = message.reactions;
                }
            });
            setMessageReactions(existingReactions);
            
            Logger.info(`Selected chat with ${contact.id}. Loading existing messages:`, {
                messageCount: existingMessages.length,
                existingReactions: existingReactions
            });

            // Update cache
            setMessageCache(prev => {
                const newCache = new Map(prev);
                
                // If cache is full, remove oldest chat
                if (newCache.size >= MAX_CACHED_CHATS) {
                    const oldestChat = newCache.keys().next().value;
                    newCache.delete(oldestChat);
                }
                
                // Add current chat to cache
                newCache.set(contact.id, existingMessages);
                return newCache;
            });
    
            try {          
                // Update cache when new messages arrive
                const existingMessages = allMessages[contact.id] || [];
                    const hasUnreadMessages = existingMessages.some(msg => 
                    msg.receiver_id === user?.id && !msg.read
                    );

                if (hasUnreadMessages && websocket.current) {
                    Logger.info('Marking unread messages as read');
                    existingMessages
                        .filter(msg => msg.receiver_id === user?.id && !msg.read)
                        .forEach(msg => {
                            sendWebSocketMessage({
                                type: 'message_status',
                                message_id: msg.id,
                                status: 'read',
                                content: msg.content
                            });
                        });
                }
        
                setUnreadMessages(prev => ({
                    ...prev,
                    [contact.id]: 0
                }));
                
            } catch (error) {
                Logger.error('Error selecting chat:', error);
            }
            // Return a promise that resolves when the chat is ready
            return new Promise(resolve => {
                // Use requestAnimationFrame to ensure state updates have completed
                requestAnimationFrame(() => {
                    resolve(existingMessages);
                });
            });
        }
        
    }, [allMessages, currentChat, showChatBox, user, messageCache]);

    // Message Handling
    const handleWebSocketMessage = useCallback((event) => {
        if (!user?.id) {
            Logger.error('No user ID available in handleWebSocketMessage');
            return;
        }

        try {
            const messageData = JSON.parse(event.data);
            const currentUserId = parseInt(user.id);
            const currentChatId = currentChatIdRef.current;

            if (messageData.type === "online_status") {
                setOnlineUsers(prev => new Set(messageData.online_users.map(id => parseInt(id))));
                return;
            }

            if (messageData.type === 'typing_status') {
                if (messageData.isTyping) {
                    setTypingUsers(prev => new Set(prev).add(messageData.userId));
                } else {
                    setTypingUsers(prev => {
                        const next = new Set(prev);
                        next.delete(messageData.userId);
                        return next;
                    });
                }
                return;
            }
            
            if (messageData.type === "message") {
                // Create new message object with all required fields
                const newMessage = {
                    ...messageData,
                    reply_to_id: messageData.reply_to_id || null,
                    forwarded: messageData.forwarded || false,
                    delivered: false,
                    read: false
                };

                Logger.info('Received new message details:', newMessage);

                const senderId = parseInt(newMessage.sender_id);
                const receiverId = parseInt(newMessage.receiver_id);
                const chatId = senderId === currentUserId ? receiverId : senderId;

                // Batch update allMessages
                setAllMessages(prev => {
                    const existing = prev[chatId] || [];
                    // Only add if message doesn't exist
                    if (!existing.some(msg => msg.id === newMessage.id)) {
                        return {
                            ...prev,
                            [chatId]: sortMessages([...existing, newMessage])
                        };
                    }
                    return prev;
                });

                // If this is the current chat, update currentMessages
                if (chatId === currentChatId) {
                    setCurrentMessages(prev => {
                        // Only add if message doesn't exist
                        if (!prev.some(msg => msg.id === newMessage.id)) {
                            const updatedMessages = sortMessages([...prev, newMessage]);
                            
                            // Use requestAnimationFrame to batch UI updates
                            requestAnimationFrame(() => {
                                // Reset list measurements for proper height calculations
                                if (listRef.current) {
                                    listRef.current.resetAfterIndex(prev.length);
                                }
                            });

                            return updatedMessages;
                        }
                        return prev;
                    });

                    // Handle read status if message is received and user is at bottom
                    if (receiverId === currentUserId && isAtBottom && 
                        websocket.current?.readyState === WebSocket.OPEN) {
                        // Send read receipt
                        sendWebSocketMessage({
                            type: 'message_status',
                            message_id: newMessage.id,
                            status: 'read',
                            content: newMessage.content
                        });
                    }
                } else if (receiverId === currentUserId) {
                    // Update unread count for other chats
                    setUnreadMessages(prev => ({
                        ...prev,
                        [chatId]: (prev[chatId] || 0) + 1
                    }));
                }

                // Update message cache
                setMessageCache(prev => {
                    const newCache = new Map(prev);
                    if (newCache.has(chatId)) {
                        const cachedMessages = newCache.get(chatId);
                        if (!cachedMessages.some(msg => msg.id === newMessage.id)) {
                            newCache.set(chatId, sortMessages([...cachedMessages, newMessage]));
                        }
                    }
                    return newCache;
                });
            }

            if (messageData.type === "message_forward") {
                const {
                    receiver_id
                } = messageData;

                const receiverId = parseInt(receiver_id);
                
                Logger.info('Received forwarded message details:', messageData);
                
                // Create new message object and ensure forwarded flag is set
                const newMessage = {
                    ...messageData,
                    forwarded: true  // Explicitly set forwarded flag
                };
            
                // Update allMessages state
                setAllMessages(prev => ({
                    ...prev,
                    [receiverId]: [...(prev[receiverId] || []), newMessage]
                }));
            }

            if (messageData.type === "message_reaction") {
                const { message_id, reaction_counts } = messageData;
                Logger.info('Received reaction update:', messageData);
                setMessageReactions(prev => ({
                    ...prev,
                    [message_id]: reaction_counts
                }));
            }

            if (messageData.type === "message_edit") {
                const { message_id, content, edited_at } = messageData;
                Logger.info('Received edited message details:', messageData);
                
                // Update message in current messages
                setCurrentMessages(prev =>
                    prev.map(msg =>
                        msg.id === message_id
                            ? { ...msg, content, is_edited: true, edited_at }
                            : msg
                    )
                );
              
              // Update message in all messages
              setAllMessages(prev => {
                  const newMessages = { ...prev };
                  Object.keys(newMessages).forEach(chatId => {
                      newMessages[chatId] = newMessages[chatId].map(msg =>
                          msg.id === message_id
                              ? { ...msg, content, is_edited: true, edited_at }
                              : msg
                      );
                  });
                  return newMessages;
              });
            }

            // Add to WebSocket message handler
            if (messageData.type === "message_delete") {
              const { message_id, deleted_for_everyone, display_content } = messageData;
              Logger.info('Received deleted message details:', messageData);
              
              // Update message in current messages
              setCurrentMessages(prev =>
                  prev.map(msg =>
                      msg.id === message_id
                          ? {
                              ...msg,
                              is_deleted: true,
                              deleted_for_everyone,
                              display_content: display_content || msg.display_content,
                              file_path: null
                          }
                          : msg
                  )
              );
              
              // Update message in all messages
              setAllMessages(prev => {
                  const newMessages = { ...prev };
                  Object.keys(newMessages).forEach(chatId => {
                      newMessages[chatId] = newMessages[chatId].map(msg =>
                          msg.id === message_id
                              ? {
                                  ...msg,
                                  is_deleted: true,
                                  deleted_for_everyone,
                                  display_content: display_content || msg.display_content,
                                  file_path: null
                              }
                              : msg
                      );
                  });
                  return newMessages;
              });
            }

            if (messageData.type === "message_status") {
                updateMessageStatus(messageData);
            }
            
        } catch (error) {
            Logger.error('Error processing WebSocket message:', error, { currentChatId: currentChatIdRef.current });
        }
    }, [user, isAtBottom, updateMessageStatus]);

    // WebSocket Connection
    const connectWebSocket = useCallback((token) => {
        if (!token || !user?.id) {
            Logger.info('Connection requirements not met:', {
                hasToken: !!token,
                userId: user?.id
            });
            return;
        }
            
        if (websocket.current && websocket.current.readyState === WebSocket.OPEN) {
            return;
        }
    
        const connect = () => {
            try {
                const wsProtocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
                const wsUrl = `${wsProtocol}//${window.location.host}/ws/${token}`;
                Logger.info('Attempting WebSocket connection:', wsUrl);
                const ws = new WebSocket(wsUrl);
    
                ws.onopen = () => {
                    websocket.current = ws;
                    Logger.info('WebSocket connected for user:', user.id);
                };
    
                ws.onmessage = (event) => {
                    // Ensure we have the latest user context and token
                    const currentToken = localStorage.getItem('token');
                    if (!user?.id || !currentToken) {
                        Logger.error('No user context or token in message handler');
                        return;
                    }
                    handleWebSocketMessage(event);
                };
    
                ws.onclose = () => {
                    websocket.current = null;
                    Logger.info('WebSocket closed for user:', user.id);
                    
                    // Try to reconnect if we still have valid token and user
                    const currentToken = localStorage.getItem('token');
                    if (currentToken && user?.id) {
                        setTimeout(() => connectWebSocket(currentToken), 3000);
                    }
                };
    
            } catch (error) {
                Logger.error('WebSocket connection failed:', error);
                websocket.current = null;
            }
        };
    
        connect();
    }, [user?.id, handleWebSocketMessage]);

    const globalSearch = useCallback(
        async (query) => {
            if (!query.trim()) {
                setGlobalSearchResults({ chats: [], messages: [] });
                setIsSearching(false);
                return;
            }
    
            setIsSearching(true);
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(
                    `${API_URL}/search?query=${encodeURIComponent(query)}`,
                    {
                        headers: { Authorization: `Bearer ${token}` }
                    }
                );
                setGlobalSearchResults(response.data);
            } catch (error) {
                Logger.error('Failed to search:', error);
                setGlobalSearchResults({ chats: [], messages: [] });
            } finally {
                setIsSearching(false);
            }
        },
        []
    );
    
    const scrollToMessage = useCallback((messageId) => {
        if (!listRef.current || !currentMessages.length) return;

        const messageIndex = currentMessages.findIndex(msg => msg.id === messageId);
        if (messageIndex === -1) return;

        // First reset list measurements to ensure accurate positioning
        listRef.current.resetAfterIndex(0);

        // Remove any existing highlights
        document.querySelectorAll('[data-highlight="true"]').forEach(el => {
            el.setAttribute('data-highlight', 'false');
        });

        // Calculate total index including date headers
        let totalIndex = messageIndex;
        const dates = new Set();
        
        // Count unique dates before our target message
        currentMessages.slice(0, messageIndex + 1).forEach(msg => {
            const date = new Date(msg.timestamp).toLocaleDateString();
            dates.add(date);
        });
        
        // Add the number of date headers to our index
        totalIndex += dates.size - 1;

        // Sequence of scroll operations to ensure reliable positioning
        const scrollSequence = async () => {
            // Initial rough scroll to get the message in view
            listRef.current.scrollToItem(totalIndex, 'center');
            
            // Wait for the list to update
            await new Promise(resolve => setTimeout(resolve, 50));

            const container = messagesContainerRef.current;
            const messageElement = document.querySelector(`[data-message-id="${messageId}"]`);
            
            if (container && messageElement) {
                const containerHeight = container.clientHeight;
                const remainingMessages = currentMessages.length - messageIndex;
                const isLastPage = remainingMessages * 50 < containerHeight; // Approximate message height

                // If we're on the last page of messages
                if (isLastPage) {
                    const distanceFromBottom = container.scrollHeight - 
                        (messageElement.offsetTop + messageElement.offsetHeight);
                    
                    // If there's not enough space below the message
                    if (distanceFromBottom < containerHeight / 2) {
                        listRef.current.scrollToItem(totalIndex, 'end');
                    } else {
                        listRef.current.scrollToItem(totalIndex, 'center');
                    }
                } else {
                    // For all other messages, center them
                    listRef.current.scrollToItem(totalIndex, 'center');
                }

                // Add highlight with a slight delay to ensure scroll is complete
                setTimeout(() => {
                    messageElement.setAttribute('data-highlight', 'true');
                    
                    // Remove highlight after animation completes (pulse + highlight duration)
                    setTimeout(() => {
                        messageElement.setAttribute('data-highlight', 'false');
                    }, 5000); 
                }, 500);
            }
        };

        // Start the scroll sequence
        requestAnimationFrame(() => {
            scrollSequence().catch(err => {
                Logger.error('Error during scroll sequence:', err);
            });
        });
    }, [currentMessages]);

    const handleSendMessage = useCallback(async (content) => {
        if (!content.trim() || !currentChat?.id || sendingRef.current) return;
        if (!websocket.current || websocket.current.readyState !== WebSocket.OPEN) {
            Logger.error('WebSocket connection not open');
        }

        const token = localStorage.getItem('token');
        if (!token) {
            Logger.error('No token found');
        }
        
        sendingRef.current = true;

        try {
            if (replyingTo) {
                // Send reply using dedicated function
                await sendReplyMessage(content, replyingTo);
                Logger.info('Sent reply message:', content, replyingTo);
                setReplyingTo(null); // Clear reply state after sending
            } else {
                // Send normal message
                await sendRegularMessage(content, currentChat.id);
                Logger.info('Sent regular message:', content, currentChat.id);
            }
        } catch (error) {
            Logger.error('Failed to send message:', error);
        } finally {
            setTimeout(() => {
                sendingRef.current = false;
            }, 500);
            setIsTypingMessage(false);
        }
    }, [currentChat, replyingTo, sendReplyMessage, sendRegularMessage]);

    // Effects
    useEffect(() => {
        document.body.classList.toggle('dark-theme', theme === 'dark');
        localStorage.setItem('theme', theme === 'dark' ? 'dark' : 'light');
    }, [theme]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
      const timeouts = typingTimeouts.current; // create a local variable
      return () => {
        timeouts.forEach(timeout => clearTimeout(timeout)); // use the local variable
        timeouts.clear();
      };
    }, []);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) return;  // Don't verify token during password change
    
        const verifyToken = async () => {
            try {
                const response = await axios.get(`${API_URL}/verify-token`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                
                setUser({
                    id: response.data.id,
                    username: response.data.username
                });
                
            } catch (error) {
                localStorage.removeItem('token');
                setUser(null);
                setPage('login');
            }
        };
    
        // Initial verification
        verifyToken();
    
        // Set up interval for subsequent checks
        verifyTimeoutRef.current = setInterval(verifyToken, 300000); // 5 minutes
    
        return () => {
            if (verifyTimeoutRef.current) {
                clearInterval(verifyTimeoutRef.current);
            }
        };
    }, []);

    // Cleanup
    useEffect(() => {
        let timeoutId = userScrollTimeoutRef.current;
        return () => {
            if (websocket.current) {
                websocket.current.close();
            }
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, []);

    // Add an effect to monitor currentChat changes
    useEffect(() => {
        if (!user?.id || !localStorage.getItem('token')) return;
        currentChatIdRef.current = currentChat?.id || null;
        Logger.info('Updated currentChatIdRef:', { 
            currentChatId: currentChatIdRef.current,
            currentChat 
        });
    }, [currentChat, user]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (user?.id && token) {
            Logger.info('Initiating WebSocket connection for user:', user.id);
            connectWebSocket(token);
        }
    }, [user?.id, connectWebSocket]);

    useEffect(() => {
        if (!user?.id || !localStorage.getItem('token')) return;
        const fetchInitialUnreadMessages = async () => {
            try {
                const token = localStorage.getItem('token');
                Logger.info('Fetching initial unread messages...');
                const response = await axios.get(`${API_URL}/unread-messages`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                const unreadData = response.data.unread_messages.reduce((acc, { sender_id, unread_count }) => {
                    acc[sender_id] = unread_count;
                    return acc;
                }, {});
                setUnreadMessages(unreadData);
                Logger.info('Fetched initial unread messages:', response.data);
            } catch (error) {
                Logger.error('Failed to fetch initial unread messages:', error);
            }
        };

        if (user?.id) {  // Already had this check
            fetchInitialUnreadMessages();
        }
    }, [user]);

    useEffect(() => {
        if (!user?.id || !localStorage.getItem('token')) return;
        localStorage.setItem('unreadMessages', JSON.stringify(unreadMessages));
        Logger.info('Saved unread messages:', unreadMessages);
    }, [unreadMessages, user]);

    useEffect(() => {
        const savedUnreadMessages = localStorage.getItem('unreadMessages');
        if (savedUnreadMessages) {
            setUnreadMessages(JSON.parse(savedUnreadMessages));
        }
    }, []);

    useEffect(() => {
        if (currentChat?.id && websocket.current) {
            const handleMessageDelivery = () => {
                const chatMessages = allMessages[currentChat.id] || [];
                const pendingMessages = chatMessages.filter(msg => !msg.delivered && msg.sender_id === user?.id);

                pendingMessages.forEach(msg => {
                    sendWebSocketMessage({
                        type: 'message_status',
                        message_id: msg.id,
                        status: 'delivered',
                        content: msg.content
                    });
                });
            };

            if (onlineUsers.has(currentChat.id)) {
                handleMessageDelivery();
            }
        }
    }, [onlineUsers, currentChat, allMessages, user]);

    // Monitor scroll events
    useEffect(() => {
        const messagesContainer = messagesContainerRef.current;
        if (messagesContainer) {
            const handleScroll = (e) => {
                const { scrollTop, scrollHeight, clientHeight } = e.target;
                const isNearBottom = scrollHeight - scrollTop - clientHeight < 60;
                setIsAtBottom(isNearBottom);
                setShowScrollButton(!isNearBottom);
            };

            messagesContainer.addEventListener('scroll', handleScroll);

            return () => {
                messagesContainer.removeEventListener('scroll', handleScroll);
            };
        }
    }, [messagesContainerRef]);

    useEffect(() => {
        const currentTypingTimeouts = typingTimeouts.current;
        return () => {
            // Use the local variable instead of directly accessing the ref
            Object.values(currentTypingTimeouts).forEach(timeout => {
                if (timeout) clearTimeout(timeout);
            });
            sendingRef.current = false;
        };
    }, []);

    // Scroll to bottom when the last message is from the current user and the user is at the bottom
    useEffect(() => {
        if (currentMessages.length > 0 && isAtBottom) {
            scrollToBottom();
        }
    }, [currentMessages, isAtBottom, scrollToBottom]);

    useEffect(() => {
        if (currentChat?.id && currentMessages.length > 0) {
            setMessageCache(prev => {
                const newCache = new Map(prev);
                newCache.set(currentChat.id, currentMessages);
                return newCache;
            });
        }
    }, [currentChat?.id, currentMessages]);

    // Add this to App component to handle viewport height
    useEffect(() => {
        const updateHeight = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };

        window.addEventListener('resize', updateHeight);
        updateHeight(); // Initial call

        return () => window.removeEventListener('resize', updateHeight);
    }, []);

    const switchChat = useCallback((contact, messageId = null) => {
        // Cancel any pending operations
        sendingRef.current = false;
        if (typingTimeouts.current) {
          typingTimeouts.current.clear();
        }
        
        // First switch to the chat
        handleChatSelect(contact).then(() => {
            // After chat switch is complete and messages are loaded, scroll to message if provided
            if (messageId) {
                // Add a small delay to ensure messages are rendered
                setTimeout(() => {
                    scrollToMessage(messageId);
                }, 500);
            }
        });
    }, [handleChatSelect, scrollToMessage]);

    // Render Methods
    // Enhanced Search Component
    const GlobalSearch = () => {
        const handleSearchSelect = useCallback((contact) => {
            switchChat(contact);
            setGlobalSearchQuery('');
            setGlobalSearchResults({ chats: [], messages: [] });
            setIsTypingGlobalSearchBar(false);
        }, []);

        const handleMessageSelect = useCallback(async (message) => {
            const contact = contacts.find(c => c.id === message.chat_id);
            if (contact) {
                try {
                    await handleChatSelect(contact);
                    
                    // Wait for state updates and UI to render
                    await new Promise(resolve => {
                        requestAnimationFrame(() => {
                            if (listRef.current) {
                                listRef.current.resetAfterIndex(0);
                            }
                            setTimeout(resolve, 100);
                        });
                    });
                    
                    scrollToMessage(message.id);
                    
                    // Clear search
                    setGlobalSearchQuery('');
                    setGlobalSearchResults({ chats: [], messages: [] });
                    setIsTypingGlobalSearchBar(false);
                } catch (error) {
                    Logger.error('Error selecting message:', error);
                }
            }
        }, []); // Remove unnecessary dependencies since they're stable references

        const handleClear = useCallback(() => {
            setGlobalSearchQuery('');
            setGlobalSearchResults({ chats: [], messages: [] });
            setIsTypingGlobalSearchBar(false);
        }, []);

        const handleSearch = useCallback((value) => {
            setGlobalSearchQuery(value);
            if (value.trim()) {
                globalSearch(value);
            }
        }, []);

        return (
            <div className="search-container">
                <GlobalSearchInput
                    initialValue={globalSearchQuery} 
                    onSearch={handleSearch}
                    onClear={handleClear}
                    setIsTypingGlobalSearchBar={setIsTypingGlobalSearchBar}
                    isTypingGlobalSearchBar={isTypingGlobalSearchBar}
                    isTypingMessage={isTypingMessage}
                    setIsTypingMessage={setIsTypingMessage}
                />

                {globalSearchQuery && (
                    <div className="enhanced-search-results">
                        <MemoizedGlobalSearchResults
                            query={globalSearchQuery}
                            contacts={contacts}
                            onContactSelect={handleSearchSelect}
                            globalSearchResults={globalSearchResults}
                            isSearching={isSearching}
                            user={user}
                            formatTimestampToDate={formatTimestampToDate}
                            formatTime={formatTime}
                            onMessageSelect={handleMessageSelect}
                        />
                    </div>
                )}
            </div>
        );
    };

    // Search Messages Component
    const SearchMessages = () => {
        const handleSearchClose = useCallback(() => {
            setShowMessageSearch(false);
            setMessageSearchQuery('');
            setIsTypingMessageSearchBar(false);
        }, []);

        const handleClear = useCallback(() => {
            setMessageSearchQuery('');
            setIsTypingMessageSearchBar(false);
        }, []);

        const handleMessageSelect = useCallback((messageId) => {
            scrollToMessage(messageId);
            handleSearchClose();
            setIsTypingMessageSearchBar(false);
        }, [handleSearchClose]);

        const handleMessageSearch = useCallback((query) => {
            setMessageSearchQuery(query);
        }, []);

        return (
            <div className={`search-messages-container ${showMessageSearch ? 'active' : ''}`}>
                <div className="search-messages-header">
                    <MessageSearchInput
                        initialValue={messageSearchQuery} 
                        onSearch={handleMessageSearch}
                        onClear={handleClear}
                        onClose={handleSearchClose}
                        setIsTypingMessageSearchBar={setIsTypingMessageSearchBar}
                        isTypingMessageSearchBar={isTypingMessageSearchBar}
                        isTypingMessage={isTypingMessage}
                    />
                </div>

                <div className="search-results">
                    <MemoizedSearchResults
                        query={messageSearchQuery}
                        currentMessages={currentMessages}
                        currentChat={currentChat}
                        user={user}
                        formatTimestampToDate={formatTimestampToDate}
                        formatTime={formatTime}
                        onMessageSelect={handleMessageSelect}
                        isSearching={isSearching}
                    />
                </div>
            </div>
        );
    };

    const ForwardDialog = () => {
        if (!showForwardDialog) return null;
    
        return (
            <div className="modal-overlay">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3>Forward Message</h3>
                        <button 
                            className="modal-close"
                            onClick={() => {
                                setShowForwardDialog(false);
                                setForwardingMessage(null);
                            }}
                        >
                            ×
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="forward-message-preview">
                            {forwardingMessage?.content}
                        </div>
                        <div className="contacts-list forward-contacts">
                            {contacts
                                .filter(contact => contact.id !== currentChat?.id && contact.id !== forwardingMessage?.sender_id)
                                .map(contact => (
                                    <div
                                        key={contact.id}
                                        className="contact forward-contact"
                                        onClick={() => handleForwardMessage(forwardingMessage, contact.id)}
                                    >
                                        <div className="contact-name">
                                            {contact.username}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const resetListAfterIndex = useCallback(
        (index) => {
            if (listRef.current) {
                listRef.current.resetAfterIndex(index, true);
            }
        },
        []
    );

    // In App.js, update the chat header:
    const MemoizedChatHeader = memo(({ 
        currentChat, 
        typingUsers, 
        onlineUsers, 
        formatLastSeen, 
        onSearchToggle 
    }) => {
        const isTyping = typingUsers.has(currentChat?.id);
        const isOnline = onlineUsers.has(currentChat?.id);
        
        return (
            <div className="chat-header">
                <div className="chat-header-info">
                    <h3>{currentChat?.username}</h3>
                    <span className="chat-status">
                        <TypingIndicator 
                            isTyping={isTyping} 
                            username={currentChat?.username} 
                        />
                        {!isTyping && (
                            isOnline ? 
                                <span className="online-status">online</span> :
                                currentChat?.last_seen ? 
                                    <span className="last-seen-status">
                                        Last seen {formatLastSeen(currentChat.last_seen)}
                                    </span> : 
                                    'Never seen'
                        )}
                    </span>
                </div>
                <div className="chat-header-actions">
                    <button
                        className="search-toggle"
                        onClick={onSearchToggle}
                        aria-label="Search messages"
                    >
                        🔍
                    </button>
                </div>
            </div>
        );
    }, (prevProps, nextProps) => {
        const typingStateUnchanged = 
            prevProps.typingUsers.has(prevProps.currentChat?.id) === 
            nextProps.typingUsers.has(nextProps.currentChat?.id);
            
        const onlineStateUnchanged = 
            prevProps.onlineUsers.has(prevProps.currentChat?.id) === 
            nextProps.onlineUsers.has(nextProps.currentChat?.id);
            
        const chatUnchanged = 
            prevProps.currentChat?.id === nextProps.currentChat?.id &&
            prevProps.currentChat?.username === nextProps.currentChat?.username &&
            prevProps.currentChat?.last_seen === nextProps.currentChat?.last_seen;
            
        return typingStateUnchanged && onlineStateUnchanged && chatUnchanged;
    });

    // Update the RenderedMessage component
    const renderSingleMessage = useCallback(({ message, style, index }) => {
        const isOwnMessage = message.sender_id === user?.id;
        
        return (
            <MemoizedMessage
                key={`${message.id}-${message.is_edited}-${message.read}-${message.delivered}`}
                message={message}
                style={style}
                index={index}
                isOwnMessage={isOwnMessage}
                user={user}
                currentChat={currentChat}
                currentMessages={currentMessages}
                editingMessage={editingMessage}
                messageToDelete={messageToDelete}
                setEditingMessage={setEditingMessage}
                setMessageToDelete={setMessageToDelete}
                handleEditMessage={handleEditMessage}
                handleDeleteMessage={handleDeleteMessage}
                setForwardingMessage={setForwardingMessage}
                setShowForwardDialog={setShowForwardDialog}
                showReactionPicker={showReactionPicker}
                setShowReactionPicker={setShowReactionPicker}
                handleReaction={handleReaction}
                messageReactions={messageReactions}
                messageHeights={messageHeights}
                MessageStatus={MessageStatus}
                onReply={handleReply}
                listRef={listRef}
                scrollToMessage={scrollToMessage}
            />
        );
    }, [
        user,
        currentChat,
        currentMessages,
        editingMessage,
        messageToDelete,
        handleEditMessage,
        handleDeleteMessage,
        showReactionPicker,
        handleReaction,
        messageReactions,
        handleReply,
        scrollToMessage,
    ]);
    
    const stableMessageProps = useMemo(() => ({
        editingMessage,
        messageToDelete,
        setEditingMessage,
        setMessageToDelete,
        handleEditMessage,
        handleDeleteMessage,
        setForwardingMessage,
        setShowForwardDialog,
        showReactionPicker,
        setShowReactionPicker,
        handleReaction,
        messageReactions,
        messageHeights,
        resetListAfterIndex,
        MessageStatus,
    }), [
        editingMessage,
        messageToDelete,
        handleEditMessage,
        handleDeleteMessage,
        showReactionPicker,
        messageReactions,
        resetListAfterIndex,
        handleReaction,
    ]);

    const renderMessages = useCallback(() => {
        if (!currentMessages || currentMessages.length === 0) {
            return <p>No messages</p>;
        }
    
        return (
            <StableMessageList
                messages={currentMessages}
                messageHeights={messageHeights}
                onScroll={handleScroll}
                listRef={listRef}
                renderMessage={renderSingleMessage}
                messageProps={stableMessageProps}
                containerHeight={messagesContainerRef.current?.clientHeight}
                key={currentChat?.id}
            />
        );
    }, [
        currentMessages,
        currentChat?.id,
        renderSingleMessage,
        stableMessageProps,
        handleScroll,
    ]);
      
    const renderMobilePage = () => {
        // If not logged in, show auth pages
        if (!user) {
            if (window.location.pathname === '/register') {
                return (
                    <div className="auth-page">
                        <div className="auth-container">
                            <div className="auth-header">
                                <h1>Register</h1>
                                <button
                                    onClick={toggleTheme}
                                    className="theme-toggle-btn"
                                    aria-label={`Switch to ${theme === 'light' ? 'dark' : 'light'} mode`}
                                >
                                    {theme === 'light' ? '🌙' : '🌞'}
                                </button>
                            </div>
                            <RegisterForm 
                                onShowLogin={() => navigate('/login')}
                            />
                        </div>
                    </div>
                );
            }

            if (window.location.pathname === '/reset-password') {
                return (
                    <ResetPasswordForm 
                        token={resetToken} 
                        isDarkMode={theme === 'dark'}
                        onThemeToggle={toggleTheme}
                    />
                );
            }

            // Default to login form
            return (
                <div className="auth-page">
                    <div className="auth-container">
                        <div className="auth-header">
                            <h1>Chat App</h1>
                            <button
                                onClick={toggleTheme}
                                className="theme-toggle-btn"
                                aria-label={`Switch to ${theme === 'light' ? 'dark' : 'light'} mode`}
                            >
                                {theme === 'light' ? '🌙' : '🌞'}
                            </button>
                        </div>
                        <LoginForm 
                            onLogin={handleLogin} 
                            error={error}
                            onShowResetPassword={() => navigate('/reset-password')}
                            onShowRegister={() => navigate('/register')}
                        />
                    </div>
                </div>
            );
        }

        // Get users with existing chats
        const usersWithChats = contacts.filter(contact => 
            allMessages[contact.id] && allMessages[contact.id].length > 0
        );

        // Determine which contacts to show based on showAllUsers state
        const displayedContacts = showAllUsers ? contacts : usersWithChats;

        return (
            <div className={`chat-container ${theme === 'dark' ? 'dark-theme' : ''}`}>
                {showChatBox ? (
                    <div className="chat-main">
                        {currentChat ? (
                            <>
                                <div className="chat-header-mobile">
                                    <div className="chat-header-info-mobile">
                                        <button onClick={() => setShowChatBox(false)} className="back-button">
                                            ←
                                        </button>
                                        <h3>{currentChat.username}</h3>
                                        <span className="chat-status">
                                            {typingUsers.has(currentChat.id) ? (
                                                <span className="typing-status">typing...</span>
                                            ) : onlineUsers.has(currentChat.id) ? (
                                                <span className="online-status">online</span>
                                            ) : currentChat?.last_seen ? (
                                                <span className="last-seen-status">
                                                    Last seen {formatLastSeen(currentChat.last_seen)}
                                                </span>
                                            ) : ('Never seen')}
                                        </span>
                                        <div className="chat-header-actions">
                                            <button
                                                className="search-toggle"
                                                onClick={() => {
                                                    setShowMessageSearch(true);
                                                }}
                                            >
                                                🔍
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <SearchMessages />
                                <div
                                    className="messages-container-mobile"
                                    ref={messagesContainerRef}
                                    onScroll={handleScroll}
                                >
                                    {renderMessages()}
                                </div>
                                <ScrollToBottomButton
                                    onClick={scrollToBottom}
                                    visible={showScrollButton}
                                />
                                <div className="message-input-mobile">
                                    <MessageInput
                                        onSend={handleSendMessage}
                                        onTyping={handleTypingStatus}
                                        currentChat={currentChat}
                                        replyTo={replyingTo}
                                        onCancelReply={() => setReplyingTo(null)}
                                    />
                                </div>
                            </>
                        ) : (
                            <div className="no-chat-selected">
                                <h3>Welcome to Chat App</h3>
                                <p>Select a contact to start chatting</p>
                            </div>
                        )}
                    </div>
                ) : (
                    <div className="chat-sidebar">
                        <div className="user-header">
                            <h3>{user?.username}</h3>
                            <div className="header-controls">
                                <div className="flex items-center space-x-2">
                                    <button
                                        onClick={() => {
                                            Logger.info('Opening settings menu');
                                            setShowSettings(true);
                                        }}
                                        className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                        </svg>
                                    </button>
                                    {showSettings && (
                                        <SettingsMenu
                                            onClose={() => {
                                                Logger.info('Closing settings menu');
                                                setShowSettings(false);
                                            }}
                                            user={user}
                                        />
                                    )}
                                </div>
                                <button
                                    onClick={handleNewChatToggle}
                                    className="w-10 h-10 flex items-center justify-center rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200 relative"
                                    aria-label={showAllUsers ? 'Back to Chats' : 'New Chat'}
                                >
                                    {showAllUsers ? (
                                        <svg className="w-5 h-5 text-gray-600 dark:text-gray-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                                        </svg>
                                    ) : (
                                        <>
                                            <svg className="w-6 h-6 text-gray-600 dark:text-gray-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                                            </svg>
                                            <span className="absolute top-1 right-1 w-4 h-4 bg-gray-600 dark:bg-gray-300 rounded-full flex items-center justify-center">
                                                <svg className="w-3 h-3 text-white dark:text-gray-800" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                                </svg>
                                            </span>
                                        </>
                                    )}
                                </button>
                                <button
                                    onClick={toggleTheme}
                                    className="theme-toggle-btn"
                                    aria-label={`Switch to ${theme === 'light' ? 'dark' : 'light'} mode`}
                                >
                                    {theme === 'light' ? '🌙' : '🌞'}
                                </button>
                                <button onClick={handleLogout} className="logout-btn">
                                    Logout
                                </button>
                            </div>
                        </div>
                        <GlobalSearch />
                        <div className={`contacts-list ${isTransitioning ? 'transitioning' : 'visible'}`}>
                            {showAllUsers ? (
                                <div className="new-chat-users">
                                    {contacts.filter(contact =>
                                        contact.username.toLowerCase().includes(globalSearchQuery.toLowerCase())
                                    ).map(contact => (
                                        <div 
                                            key={contact.id}
                                            className="contact"
                                            onClick={() => {
                                                handleChatSelect(contact);
                                                setShowAllUsers(false);
                                                setShowChatBox(true);
                                            }}
                                        >
                                            <div className="contact-info">
                                                <span className="contact-name">{contact.username}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : displayedContacts.length === 0 ? (
                                <div className="empty-chat-state">
                                    <svg className="w-12 h-12" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                                    </svg>
                                    <p>No chats yet. Click the message icon above to start a new conversation.</p>
                                </div>
                            ) : (
                                displayedContacts
                                    .filter(contact =>
                                        contact.username.toLowerCase().includes(globalSearchQuery.toLowerCase())
                                    )
                                    .map(contact => (
                                        <ContactItem
                                            key={contact.id}
                                            contact={contact}
                                            currentChat={currentChat}
                                            allMessages={allMessages}
                                            unreadMessages={unreadMessages}
                                            user={user}
                                            onSelect={(contact) => {
                                                handleChatSelect(contact);
                                                setShowAllUsers(false);
                                                setShowChatBox(true);
                                            }}
                                            formatTime={formatTime}
                                        />
                                    ))
                            )}
                        </div>
                    </div>
                )}
                <ForwardDialog />
            </div>
        );
    };
      
    const renderChatPage = () => {
        // Get users with existing chats
        const usersWithChats = contacts.filter(contact => 
            allMessages[contact.id] && allMessages[contact.id].length > 0
        );

        // Determine which contacts to show based on showAllUsers state
        const displayedContacts = showAllUsers ? contacts : usersWithChats;

        return (
            <div className={`chat-container ${theme === 'dark' ? 'dark-theme' : ''}`}>
                <div className="chat-sidebar">
                    <div className="user-header">
                        <h3>{user?.username}</h3>
                        <div className="header-controls">
                            <div className="flex items-center space-x-2">
                                <button
                                    onClick={() => {
                                        Logger.info('Opening settings menu');
                                        setShowSettings(true);
                                    }}
                                    className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                    </svg>
                                </button>
                                {showSettings && (
                                    <SettingsMenu
                                        onClose={() => {
                                            Logger.info('Closing settings menu');
                                            setShowSettings(false);
                                        }}
                                        user={user}
                                    />
                                )}
                            </div>
                            <button
                                onClick={handleNewChatToggle}
                                className="w-10 h-10 flex items-center justify-center rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200 relative"
                                aria-label={showAllUsers ? 'Back to Chats' : 'New Chat'}
                            >
                                {showAllUsers ? (
                                    <svg className="w-5 h-5 text-gray-600 dark:text-gray-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                                    </svg>
                                ) : (
                                    <>
                                        <svg className="w-6 h-6 text-gray-600 dark:text-gray-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                                        </svg>
                                        <span className="absolute top-1 right-1 w-4 h-4 bg-gray-600 dark:bg-gray-300 rounded-full flex items-center justify-center">
                                            <svg className="w-3 h-3 text-white dark:text-gray-800" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                            </svg>
                                        </span>
                                    </>
                                )}
                            </button>
                            <button
                                onClick={toggleTheme}
                                className="theme-toggle-btn"
                                aria-label={`Switch to ${theme === 'light' ? 'dark' : 'light'} mode`}
                            >
                                {theme === 'light' ? '🌙' : '🌞'}
                            </button>
                            <button onClick={handleLogout} className="logout-btn">
                                Logout
                            </button>
                        </div>
                    </div>
                    <GlobalSearch />
                    <div className={`contacts-list ${isTransitioning ? 'transitioning' : 'visible'}`}>
                        {showAllUsers ? (
                            <div className="new-chat-users">
                                {contacts.filter(contact =>
                                    contact.username.toLowerCase().includes(globalSearchQuery.toLowerCase())
                                ).map(contact => (
                                    <div 
                                        key={contact.id}
                                        className="contact"
                                        onClick={() => {
                                            handleChatSelect(contact);
                                            setShowAllUsers(false);
                                        }}
                                    >
                                        <div className="contact-info">
                                            <span className="contact-name">{contact.username}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : displayedContacts.length === 0 ? (
                            <div className="empty-chat-state">
                                <svg className="w-12 h-12" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                                </svg>
                                <p>No chats yet. Click the message icon above to start a new conversation.</p>
                            </div>
                        ) : (
                            displayedContacts
                                .filter(contact =>
                                    contact.username.toLowerCase().includes(globalSearchQuery.toLowerCase())
                                )
                                .map(contact => (
                                    <ContactItem
                                        key={contact.id}
                                        contact={contact}
                                        currentChat={currentChat}
                                        allMessages={allMessages}
                                        unreadMessages={unreadMessages}
                                        user={user}
                                        onSelect={(contact) => {
                                            handleChatSelect(contact);
                                            setShowAllUsers(false);
                                        }}
                                        formatTime={formatTime}
                                    />
                                ))
                        )}
                    </div>
                </div>
                <div className="chat-main">
                    {currentChat ? (
                        <>
                            <MemoizedChatHeader
                                currentChat={currentChat}
                                typingUsers={typingUsers}
                                onlineUsers={onlineUsers}
                                formatLastSeen={formatLastSeen}
                                onSearchToggle={() => {
                                    setShowMessageSearch(true);
                                }}
                            />
                            <SearchMessages />
                            <div
                                className="messages-container"
                                ref={messagesContainerRef}
                                onScroll={handleScroll}
                            >
                                {renderMessages()}
                            </div>
                            <ScrollToBottomButton
                                onClick={scrollToBottom}
                                visible={showScrollButton}
                            />
                            <MessageInput
                                onSend={handleSendMessage}
                                onTyping={handleTypingStatus}
                                currentChat={currentChat}
                                replyTo={replyingTo}
                                onCancelReply={() => setReplyingTo(null)}
                            />
                        </>
                    ) : (
                        <div className="no-chat-selected">
                            <h3>Welcome to Chat App</h3>
                            <p>Select a contact to start chatting</p>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const renderAuthPage = () => {
        return (
            <div className={`auth-page ${theme === 'dark' ? 'dark-theme' : ''}`}>
                <div className="auth-container">
                    <div className="auth-header">
                        <h1>Chat App</h1>
                        <button
                            onClick={toggleTheme}
                            className="theme-toggle-btn"
                            aria-label={`Switch to ${theme === 'light' ? 'dark' : 'light'} mode`}
                        >
                            {theme === 'light' ? '🌙' : '🌞'}
                        </button>
                    </div>
                    <LoginForm 
                        onLogin={handleLogin} 
                        error={error}
                        onShowResetPassword={() => navigate('/reset-password')}
                        onShowRegister={() => navigate('/register')}
                    />
                </div>
            </div>
        );
    };

    const renderPage = () => {
        if (isMobile) {
            return renderMobilePage();
        }
        return (
            <>
                {renderChatPage()}
                <ForwardDialog />
            </>
        );
    };

    useEffect(() => {
        if (!user && 
            !window.location.pathname.includes('reset-password') && 
            !window.location.pathname.includes('admin') && 
            !window.location.search.includes('token') &&
            !window.location.pathname.includes('register') &&
            !window.location.pathname.includes('change-password') &&
            !window.location.pathname.includes('restore-account')) {
            navigate('/login');
            setPage('login');
        }
    }, [user, navigate]);

    // Modify the reset token effect to handle the token first
    useEffect(() => {
        const token = new URLSearchParams(window.location.search).get('token');
        setResetToken(token);
    }, []);

    const handleNewChatToggle = useCallback(() => {
        setIsTransitioning(true);
        setTimeout(() => {
            // Only fetch contacts if we're showing all users and don't have any contacts yet
            if (!showAllUsers && contacts.length === 0) {
                fetchContacts();
            }
            setShowAllUsers(!showAllUsers);
            setIsTransitioning(false);
        }, 300);
    }, [showAllUsers, contacts.length, fetchContacts]);

    return (
        <div className={`app ${theme}`}>
            <Notifications />
            <Routes>
                <Route path="/login" element={!user ? (isMobile ? renderMobilePage() : renderAuthPage()) : <Navigate to="/chat" />} />
                <Route path="/chat" element={user ? renderPage() : <Navigate to="/login" />} />
                <Route path="/register" element={!user ? (
                    <div className="auth-page">
                        <div className="auth-container">
                            <div className="auth-header">
                                <h1>Register</h1>
                                <button
                                    onClick={toggleTheme}
                                    className="theme-toggle-btn"
                                    aria-label={`Switch to ${theme === 'light' ? 'dark' : 'light'} mode`}
                                >
                                    {theme === 'light' ? '🌙' : '🌞'}
                                </button>
                            </div>
                            <RegisterForm 
                                onShowLogin={() => navigate('/login')}
                            />
                        </div>
                    </div>
                ) : <Navigate to="/chat" />} />
                <Route path="/reset-password" element={
                    <ResetPasswordForm 
                        token={resetToken} 
                        isDarkMode={theme === 'dark'}
                        onThemeToggle={toggleTheme}
                    />
                } />
                <Route path="/" element={<Navigate to="/login" replace />} />
                <Route path="/change-password" element={
                    <ChangePasswordForm 
                        onClose={() => navigate('/login')}
                        onThemeToggle={toggleTheme}
                        isDarkMode={theme === 'dark'}
                        isAdminReset={isForcePasswordChange}
                        tempPassword=""
                        username={forcedPassChangeUser?.username || ''}
                    />
                } />
                <Route path="/admin/login" element={<AdminLoginTailwind />} />
                <Route path="/admin/dashboard" element={<AdminDashboardTailwind />} />
                <Route path="/restore-account" element={<RestoreAccountPage />} />
            </Routes>
        </div>
    );
}

function App() {
    return (
        <ThemeProvider>
            <AppContent />
        </ThemeProvider>
    );
}

export default App;