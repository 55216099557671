import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import ReactionPicker from './ReactionPicker';

const MemoizedMessage = ({ 
    message, 
    style,
    index,
    isOwnMessage,
    user,
    currentChat,
    currentMessages,
    editingMessage,
    messageToDelete,
    setEditingMessage,
    setMessageToDelete,
    handleEditMessage,
    handleDeleteMessage,
    setForwardingMessage,
    setShowForwardDialog,
    handleReaction,
    messageReactions,
    MessageStatus,
    onReply,
    listRef,
    scrollToMessage    
}) => {
    const messageRef = useRef(null);
    const editTextareaRef = useRef(null);
    const [showReactionPicker, setShowReactionPicker] = useState(false);

    // Auto-focus edit textarea
    useEffect(() => {
        if (editingMessage?.id === message.id && editTextareaRef.current) {
            editTextareaRef.current.focus();
            editTextareaRef.current.setSelectionRange(editTextareaRef.current.value.length, editTextareaRef.current.value.length);
        }
    }, [editingMessage, message.id]);

    const renderMessageReactions = () => {
        const reactions = messageReactions[message.id] || {};
        
        return Object.entries(reactions).length > 0 ? (
            <div className="message-reactions">
                {Object.entries(reactions).map(([emoji, count]) => (
                    <span key={emoji} className="reaction-badge">
                        {emoji}{count > 1 ? ` ${count}` : ''}
                    </span>
                ))}
            </div>
        ) : null;
    };

    // Close picker when clicking outside
    useEffect(() => {
        if (!showReactionPicker) return;

        const handleClickOutside = (event) => {
            if (messageRef.current && !messageRef.current.contains(event.target)) {
                setShowReactionPicker(false);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, [showReactionPicker]);

    // Handler for opening reaction picker
    const handleReactionClick = (e) => {
        e.stopPropagation();
        setShowReactionPicker(prev => !prev);
    };

    // Memoize the replied message to prevent re-renders
    const repliedToMessage = useMemo(() => {
        if (!message.reply_to_id) return null;
        return currentMessages.find(m => m.id === message.reply_to_id);
    }, [message.reply_to_id, currentMessages]);

    const handleReplyClick = useCallback(() => {
        if (!repliedToMessage) return;
        
        const messageIndex = currentMessages.findIndex(
            m => m.id === repliedToMessage.id
        );
        
        if (messageIndex !== -1) {
            scrollToMessage(repliedToMessage.id);
        }
    }, [repliedToMessage, currentMessages, scrollToMessage]);

    // Memoize the reply preview component
    const ReplyPreview = useMemo(() => {
        if (!message.reply_to_id || !repliedToMessage) return null;
        
        return (
            <div className="reply-preview" onClick={handleReplyClick}>
                <div className="reply-preview-author">
                    {repliedToMessage.sender_id === user?.id 
                        ? 'You'
                        : currentChat?.username || 'User'}
                </div>
                <div className="reply-preview-text">
                    {repliedToMessage.content}
                </div>
            </div>
        );
    }, [message.reply_to_id, repliedToMessage, user?.id, currentChat?.username, handleReplyClick]);

    return (
        <div
            ref={messageRef}
            style={{
                ...style,
                position: 'absolute',
                top: style.top,
                left: 0,
                right: 0,
                height: 'auto',
                minHeight: style.height
            }}
            className={`message ${isOwnMessage ? 'sent' : 'received'}`}
            data-message-id={message.id}
            data-highlight="false"
        >
            {isOwnMessage && !message.is_deleted && (
                <div className="message-actions">
                    {!message.read ? (
                        <>
                            <button
                                className="edit-button"
                                onClick={() => setEditingMessage(message)}
                                aria-label="Edit message"
                            >
                                ✎
                            </button>
                            <button
                                className="delete-button"
                                onClick={() => setMessageToDelete(message)}
                                aria-label="Delete message"
                            >
                                🗑️
                            </button>
                        </>
                    ) : (
                        <>
                            <button className="edit-button disabled" disabled title="Cannot edit read messages">✎</button>
                            <button className="delete-button disabled" disabled title="Cannot delete read messages">🗑️</button>
                        </>
                    )}
                    <button
                        className="forward-button"
                        onClick={(e) => {
                            e.stopPropagation();
                            setForwardingMessage(message);
                            setShowForwardDialog(true);
                        }}
                        aria-label="Forward message"
                    >
                        ↪
                    </button>
                    <button
                        className="reply-button"
                        onClick={() => onReply?.(message)}
                        aria-label="Reply to message"
                    >
                        ↩
                    </button>
                </div>
            )}

            <div className="message-bubble">
                {ReplyPreview}
                <div className="message-content">
                    {editingMessage?.id === message.id ? (
                        <div className="edit-message-form">
                            <textarea
                                ref={editTextareaRef}
                                defaultValue={message.content}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter' && !e.shiftKey) {
                                        e.preventDefault();
                                        handleEditMessage(message.id, e.target.value);
                                    }
                                    if (e.key === 'Escape') {
                                        setEditingMessage(null);
                                    }
                                }}
                                className="edit-textarea"
                            />
                        </div>
                    ) : (
                        <div className="message-body">
                            {message.forwarded && (
                                <div className="forwarded-label">
                                    <span className="forward-icon">↪</span>
                                    Forwarded
                                </div>
                            )}
                            <div className={`message-text ${message.is_deleted ? 'deleted' : ''}`}>
                                {message.is_deleted ? (
                                    message.deleted_for_everyone ? 
                                        'This message was deleted' : 
                                        isOwnMessage ? 'This message was deleted' : message.content
                                ) : message.content}
                            </div>
                        </div>
                    )}
                    <div className="message-info">
                        <div className="timestamp-container">
                            {message.is_edited && !message.is_deleted && (
                                <span className="edited-indicator">Edited</span>
                            )}
                            <span className="message-timestamp">
                                {new Date(message.timestamp).toLocaleTimeString([], { 
                                    hour: '2-digit', 
                                    minute: '2-digit' 
                                })}
                            </span>
                        </div>
                        {message.sender_id === user?.id && <MessageStatus message={message} />}
                    </div>
                </div>
            </div>

            {!isOwnMessage && !message.is_deleted && (
                <div className="message-actions">
                    <button
                        className="forward-button"
                        onClick={(e) => {
                            e.stopPropagation();
                            setForwardingMessage(message);
                            setShowForwardDialog(true);
                        }}
                        aria-label="Forward message"
                    >
                        ↪
                    </button>
                    <button
                        className="reply-button"
                        onClick={() => onReply(message)}
                        aria-label="Reply to message"
                    >
                        ↩
                    </button>
                    <button
                        className="reaction-trigger"
                        onClick={handleReactionClick}
                        aria-label="Add reaction"
                    >
                        😊
                    </button>
                </div>
            )}

            {showReactionPicker && !message.is_deleted && (
                <ReactionPicker
                    messageId={message.id}
                    onSelect={(emoji, messageId) => {
                        handleReaction(emoji, messageId);
                        setShowReactionPicker(false);
                    }}
                    onClose={() => setShowReactionPicker(false)}
                    parentRef={messageRef}
                />
            )}

            {renderMessageReactions()}

            {messageToDelete?.id === message.id && (
                <div className="delete-confirmation">
                    <div className="delete-confirmation-content">
                        <p>Delete this message?</p>
                        <div className="delete-actions">
                            <button 
                                className="cancel-button"
                                onClick={() => setMessageToDelete(null)}
                            >
                                Cancel
                            </button>
                            <button
                                className="delete-confirm"
                                onClick={() => handleDeleteMessage(message.id, true)}
                                aria-label="Delete message for everyone"
                            >
                                Delete for everyone
                            </button>
                            <button
                                className="delete-confirm"
                                onClick={() => handleDeleteMessage(message.id, false)}
                                aria-label="Delete message for me only"
                            >
                                Delete for me
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default React.memo(MemoizedMessage, (prevProps, nextProps) => {
    // Message content and status checks
    const messageEqual = 
        prevProps.message?.id === nextProps.message?.id &&
        prevProps.message?.content === nextProps.message?.content &&
        prevProps.message?.is_edited === nextProps.message?.is_edited &&
        prevProps.message?.read === nextProps.message?.read &&
        prevProps.message?.delivered === nextProps.message?.delivered &&
        prevProps.message?.reply_to_id === nextProps.message?.reply_to_id &&
        prevProps.message?.is_deleted === nextProps.message?.is_deleted &&
        prevProps.message?.deleted_for_everyone === nextProps.message?.deleted_for_everyone &&
        prevProps.message?.is_visible === nextProps.message?.is_visible;

    // Style check (only if position changed)
    const styleEqual = prevProps.style?.top === nextProps.style?.top;

    // Editing state checks
    const editingEqual = 
        prevProps.editingMessage?.id === nextProps.editingMessage?.id;
    const deleteEqual = 
        prevProps.messageToDelete?.id === nextProps.messageToDelete?.id;

    // Chat context check
    const chatEqual = prevProps.currentChat?.id === nextProps.currentChat?.id;

    // Reactions check - only compare reactions for this specific message
    const prevReactions = prevProps.messageReactions[prevProps.message?.id] || {};
    const nextReactions = nextProps.messageReactions[nextProps.message?.id] || {};
    const reactionsEqual = JSON.stringify(prevReactions) === JSON.stringify(nextReactions);

    return messageEqual && 
           styleEqual && 
           editingEqual && 
           deleteEqual && 
           chatEqual && 
           reactionsEqual;
});