import React from 'react';
import useContactDraft from '../hooks/UseContactDraft';

const ContactItem = ({ 
    contact, 
    currentChat, 
    allMessages, 
    unreadMessages, 
    user, 
    onSelect, 
    formatTime 
}) => {
    const contactMessages = allMessages[contact.id] || [];
    const lastMessage = contactMessages[contactMessages.length - 1];
    const unreadCount = unreadMessages[contact.id] || 0;
    const { draft } = useContactDraft(contact.id);

    // Determine if this contact is the currently selected chat
    const isSelected = currentChat?.id === contact.id;

    // Only show draft preview if:
    // 1. There is a non-empty draft
    // 2. This is not the currently selected chat
    const showDraftPreview = draft && draft.trim() && !isSelected;

    return (
        <div
            className={`contact ${isSelected ? 'active' : ''}`}
            onClick={() => onSelect(contact)}
        >
            <div className="contact-info">
                <div className="contact-header">
                    <span className="contact-name">
                        {contact.username}
                    </span>
                    {unreadCount > 0 && (
                        <span className="unread-badge">
                            {unreadCount}
                        </span>
                    )}
                </div>
                <div className="contact-last-message">
                    <span className="message-preview">
                        {showDraftPreview ? (
                            <span className="draft-preview">
                                {draft}
                            </span>
                        ) : lastMessage ? (
                            <>
                                {lastMessage.sender_id === user?.id ? 'You: ' : ''}
                                {lastMessage.content}
                            </>
                        ) : (
                            <span className="no-messages">No messages yet</span>
                        )}
                    </span>
                    <span className="message-time">
                        {lastMessage && formatTime(lastMessage.timestamp)}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default React.memo(ContactItem, (prevProps, nextProps) => {
    // Deep comparison for essential props
    const contactEqual = prevProps.contact.id === nextProps.contact.id;
    const chatEqual = prevProps.currentChat?.id === nextProps.currentChat?.id;
    const unreadEqual = prevProps.unreadMessages[prevProps.contact.id] === nextProps.unreadMessages[nextProps.contact.id];
    
    // Compare messages array
    const prevMessages = prevProps.allMessages[prevProps.contact.id] || [];
    const nextMessages = nextProps.allMessages[nextProps.contact.id] || [];
    const messagesEqual = 
        prevMessages.length === nextMessages.length &&
        (prevMessages.length === 0 || 
            (prevMessages[prevMessages.length - 1]?.id === nextMessages[nextMessages.length - 1]?.id &&
             prevMessages[prevMessages.length - 1]?.content === nextMessages[nextMessages.length - 1]?.content &&
             prevMessages[prevMessages.length - 1]?.is_edited === nextMessages[nextMessages.length - 1]?.is_edited &&
             prevMessages[prevMessages.length - 1]?.read === nextMessages[nextMessages.length - 1]?.read &&
             prevMessages[prevMessages.length - 1]?.delivered === nextMessages[nextMessages.length - 1]?.delivered));

    return contactEqual && chatEqual && unreadEqual && messagesEqual;
});