import React, { useCallback, useRef, useEffect, useState } from 'react';

// GlobalSearchInput.js
const GlobalSearchInput = React.memo(({ 
    onSearch,
    onClear,
    initialValue = '',
    setIsTypingGlobalSearchBar,
    isTypingGlobalSearchBar,
    isTypingMessage,
    setIsTypingMessage,
}) => {
    const [localValue, setLocalValue] = useState(initialValue);
    const inputRef = useRef(null);
    const placeholder = "Search chats and messages...";

    const handleFocus = useCallback(() => {
        // Immediately set typing status when focused
        setIsTypingGlobalSearchBar(true);
        if (isTypingMessage) {
            // Force message input to lose focus state
            setIsTypingMessage(false);
        }
    }, [setIsTypingGlobalSearchBar, isTypingMessage, setIsTypingMessage]);

    const handleChange = useCallback((e) => {
        const value = e.target.value;
        setLocalValue(value);
        onSearch(value);
    }, [onSearch]);

    const handleKeyDown = useCallback((e) => {
        if (e.key === 'Escape') {
            onClear();
            setIsTypingGlobalSearchBar(false);
        }
    }, [onClear, setIsTypingGlobalSearchBar]);

    const handleClear = useCallback(() => {
        setLocalValue('');
        onClear();
        setIsTypingGlobalSearchBar(false);
    }, [onClear, setIsTypingGlobalSearchBar]);

    // Add effect to sync with external value
    useEffect(() => {
        setLocalValue(initialValue);
    }, [initialValue]);

    // Add effect to focus on the GlobalSearchInput when typing in it
    useEffect(() => {
        if (isTypingGlobalSearchBar && inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    return (
        <div className="search-input-wrapper">
            <input
                ref={inputRef}
                className="search-input"
                type="text"
                placeholder={placeholder}
                value={localValue}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                onFocus={handleFocus}
                autoComplete="off"
                spellCheck="false"
                autoFocus={isTypingGlobalSearchBar && !isTypingMessage}
            />
            {localValue && (
                <button 
                    className="search-clear-button"
                    onClick={handleClear}
                    title="Clear search"
                    aria-label="Clear search"
                >
                    ×
                </button>
            )}
        </div>
    );
});

GlobalSearchInput.displayName = 'GlobalSearchInput';

export default GlobalSearchInput;