import axios from 'axios';

export const handlePasswordReset = async (token, newPassword) => {
    const response = await axios.post('/api/reset-password/reset', {
        token,
        new_password: newPassword
    });
    return response.data;
};

export const handlePasswordChange = async (username, currentPassword, newPassword) => {
    const response = await axios.post('/api/change-password', {
        username,
        current_password: currentPassword,
        new_password: newPassword
    });
    return response.data;
};

export const requestPasswordReset = async (email) => {
    const response = await axios.post('/api/reset-password/request', {
        email
    });
    return response.data;
};

export const verifyPassword = async (username, password) => {
    const response = await axios.post('/api/verify-password', {
        username,
        password
    });
    return response.data;
}; 