import React, { createContext, useContext, useState, useEffect, useMemo } from 'react';

const ThemeContext = createContext();

const themes = {
  light: {
    primary: '#128C7E',
    secondary: '#25D366',
    background: '#FFFFFF',
    surface: '#F0F2F5',
    text: '#111B21',
    textSecondary: '#667781',
    border: '#E9EDEF',
    error: '#DC3545',
    success: '#25D366',
    warning: '#FFA000',
    info: '#34B7F1',
    messageOut: '#D9FDD3',
    messageIn: '#FFFFFF',
    hover: '#F5F6F6',
    active: '#E8E8E8',
    divider: '#E9EDEF',
    scrollbar: '#CCCCCC',
    typing: '#00000026'
  },
  dark: {
    primary: '#00A884',
    secondary: '#25D366',
    background: '#111B21',
    surface: '#202C33',
    text: '#E9EDEF',
    textSecondary: '#8696A0',
    border: '#2A3942',
    error: '#DC3545',
    success: '#25D366',
    warning: '#FFA000',
    info: '#34B7F1',
    messageOut: '#005C4B',
    messageIn: '#242626',
    hover: '#202C33',
    active: '#2A3942',
    divider: '#2A3942',
    scrollbar: '#435058',
    typing: '#FFFFFF26'
  }
};

export const ThemeProvider = ({ children }) => {
  const [currentTheme, setCurrentTheme] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme || 'light';
  });

  const toggleTheme = () => {
    setCurrentTheme(prev => {
      const newTheme = prev === 'light' ? 'dark' : 'light';
      localStorage.setItem('theme', newTheme);
      return newTheme;
    });
  };

  useEffect(() => {
    const root = document.documentElement;
    const themeColors = themes[currentTheme];

    Object.entries(themeColors).forEach(([property, value]) => {
      root.style.setProperty(`--${property}`, value);
    });
  }, [currentTheme]);

  const value = useMemo(() => ({
    theme: currentTheme,
    toggleTheme,
    themes
  }), [currentTheme]);

  return (
    <ThemeContext.Provider value={value}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
}; 