import React, { useState } from 'react';

export default function ForgotPasswordForm({ onClose }) {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await fetch('/api/reset-password/request', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            if (response.ok) {
                setMessage('If an account exists with this email, password reset instructions have been sent.');
                setEmail('');
            } else {
                const data = await response.json();
                setMessage(data.error || 'Something went wrong. Please try again.');
            }
        } catch (error) {
            setMessage('Network error. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="forgot-password-modal">
            <form onSubmit={handleSubmit} className="auth-form">
                <h2>Reset Password</h2>
                {message && <div className={message.includes('sent') ? 'success-message' : 'error-message'}>{message}</div>}
                <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <button type="submit" disabled={isLoading}>
                    {isLoading ? 'Sending...' : 'Send Reset Link'}
                </button>
                <button type="button" onClick={onClose} className="secondary-button">
                    Back to Login
                </button>
            </form>
        </div>
    );
} 