import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff, Lock, Person, Email, DarkMode, LightMode } from '@mui/icons-material';
import { handlePasswordReset, requestPasswordReset } from '../services/PasswordService';
import { validatePassword, getPasswordStrengthColor, renderCapsLockWarning } from '../utils/passwordUtils';
import { toast } from 'react-toastify'; // Import toastify for toast messages

export default function ResetPasswordForm({ token, isDarkMode, onThemeToggle }) {
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState({ score: 0, message: '', requirements: {}, isStrong: false });
    const [isCapsLockOn, setIsCapsLockOn] = useState(false);
    const navigate = useNavigate();

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        setPasswordStrength(validatePassword(newPassword, confirmPassword));
    };

    const handleConfirmPasswordChange = (e) => {
        const newConfirmPassword = e.target.value;
        setConfirmPassword(newConfirmPassword);
        setPasswordStrength(validatePassword(password, newConfirmPassword));
    };

    const handleKeyDown = (e) => {
        setIsCapsLockOn(e.getModifierState('CapsLock'));
    };

    const handleKeyUp = (e) => {
        setIsCapsLockOn(e.getModifierState('CapsLock'));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            if (token) {
                // Handle password reset with token
                if (password !== confirmPassword) {
                    toast.error('Passwords do not match'); // Show error as toast message
                    setIsLoading(false);
                    return;
                }

                if (!passwordStrength.isStrong) {
                    toast.error('Please choose a stronger password'); // Show error as toast message
                    setIsLoading(false);
                    return;
                }

                const response = await handlePasswordReset(token, password);
                if (response) {
                    toast.success('Password has been reset successfully!'); // Show success as toast message
                    setPassword('');
                    setConfirmPassword('');
                    setTimeout(() => {
                        navigate('/login');
                    }, 2000);
                }
            } else {
                // Handle password reset request
                if (!email || !username) {
                    toast.error('Please fill in all fields'); // Show error as toast message
                    setIsLoading(false);
                    return;
                }

                const response = await requestPasswordReset(email, username);
                if (response) {
                    toast.success('Password reset instructions have been sent to your email.'); // Show success as toast message
                    setEmail('');
                    setUsername('');
                }
            }
        } catch (error) {
            const errorMessage = error.response?.data?.detail || 'An error occurred';
            toast.error(errorMessage); // Show error message as toast message
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={`auth-page ${isDarkMode ? 'dark-theme' : ''}`}>
            <div className="auth-container">
                <div className="auth-header">
                    <h1>{token ? 'Reset Your Password' : 'Request Password Reset'}</h1>
                    <button 
                        className="theme-toggle-btn"
                        onClick={onThemeToggle}
                        aria-label="Toggle theme"
                    >
                        {isDarkMode ? <LightMode /> : <DarkMode />}
                    </button>
                </div>

                {message && (
                    <div className={message.includes('successfully') || message.includes('sent to your email') ? 'success-message' : 'error-message'}>
                        {message}
                    </div>
                )}

                <form className="auth-form" onSubmit={handleSubmit}>
                    {!token ? (
                        <>
                            <div className="input-container">
                                <Person className="input-icon" />
                                <input
                                    type="text"
                                    placeholder="Username"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    className="auth-input"
                                    required
                                />
                            </div>

                            <div className="input-container">
                                <Email className="input-icon" />
                                <input
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="auth-input"
                                    required
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="input-container">
                                <Lock className="input-icon" />
                                <input
                                    type={showPassword ? "text" : "password"}
                                    placeholder="New Password"
                                    value={password}
                                    onChange={handlePasswordChange}
                                    onKeyDown={handleKeyDown}
                                    onKeyUp={handleKeyUp}
                                    className="auth-input"
                                    required
                                />
                                <button
                                    type="button"
                                    className="visibility-toggle"
                                    onClick={() => setShowPassword(!showPassword)}
                                    tabIndex="-1"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </button>
                            </div>

                            {password && (
                                <div className="password-strength-container">
                                    <div className="password-strength-bar">
                                        <div 
                                            className="password-strength-progress"
                                            style={{
                                                width: `${(passwordStrength.score / 6) * 100}%`,
                                                backgroundColor: getPasswordStrengthColor(passwordStrength.score)
                                            }}
                                        />
                                    </div>
                                    <div className="password-strength-text">
                                        <span className="strength-label">Password Strength:</span>
                                        <span 
                                            className="strength-message" 
                                            style={{ color: getPasswordStrengthColor(passwordStrength.score) }}
                                        >
                                            {passwordStrength.message}
                                        </span>
                                    </div>
                                    <div className="password-requirements">
                                        <div className={passwordStrength.requirements.length ? 'met' : ''}>
                                            • At least 8 characters
                                        </div>
                                        <div className={passwordStrength.requirements.uppercase ? 'met' : ''}>
                                            • At least one uppercase letter
                                        </div>
                                        <div className={passwordStrength.requirements.lowercase ? 'met' : ''}>
                                            • At least one lowercase letter
                                        </div>
                                        <div className={passwordStrength.requirements.number ? 'met' : ''}>
                                            • At least one number
                                        </div>
                                        <div className={passwordStrength.requirements.special ? 'met' : ''}>
                                            • At least one special character
                                        </div>
                                        <div className={passwordStrength.requirements.match ? 'met' : ''}>
                                            • Passwords match
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="input-container">
                                <Lock className="input-icon" />
                                <input
                                    type={showConfirmPassword ? "text" : "password"}
                                    placeholder="Confirm Password"
                                    value={confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                    onKeyDown={handleKeyDown}
                                    onKeyUp={handleKeyUp}
                                    className="auth-input"
                                    required
                                />
                                <button
                                    type="button"
                                    className="visibility-toggle"
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                    tabIndex="-1"
                                >
                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </button>
                            </div>

                            {renderCapsLockWarning(isCapsLockOn)}
                        </>
                    )}

                    <button 
                        type="submit" 
                        disabled={isLoading || (token ? (!password || !confirmPassword || !passwordStrength.isStrong || password !== confirmPassword) : (!email || !username))}
                        className={`submit-button ${isLoading ? 'loading' : ''}`}
                    >
                        {isLoading ? (token ? 'Resetting...' : 'Sending...') : (token ? 'Reset Password' : 'Send Reset Link')}
                    </button>

                    <div className="auth-links">
                        <button
                            type="button"
                            className="link-button"
                            onClick={() => navigate('/login')}
                        >
                            Back to Login
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
} 