//src/components/LoginForm.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Person, Lock, Visibility, VisibilityOff } from '@mui/icons-material';
import ForgotPasswordForm from './ForgotPasswordForm';
import ChangePasswordForm from './ChangePasswordForm';
import { renderCapsLockWarning } from '../utils/passwordUtils';
import { toast } from 'react-hot-toast';

export default function LoginForm({ onLogin }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isCapsLockOn, setIsCapsLockOn] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const handleKeyDown = (e) => {
            // Check for Ctrl + Shift + A
            if (e.ctrlKey && e.shiftKey && e.key.toLowerCase() === 'a') {
                e.preventDefault();
                navigate('/admin/login');
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [navigate]);

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!username || !password) {
            toast.error('Please fill in all fields');
            return;
        }
        try {
            await onLogin(username, password);
        } catch (err) {
            toast.error(err.response?.data?.detail || 'Invalid credentials');
            setPassword('');
        }
    };

    const handleKeyDown = (e) => {
        setIsCapsLockOn(e.getModifierState('CapsLock'));
    };

    const handleKeyUp = (e) => {
        setIsCapsLockOn(e.getModifierState('CapsLock'));
    };

    if (showForgotPassword) {
        return <ForgotPasswordForm onClose={() => setShowForgotPassword(false)} />;
    }

    if (showChangePassword) {
        return <ChangePasswordForm onClose={() => setShowChangePassword(false)} />;
    }

    return (
        <form onSubmit={handleSubmit} className="auth-form">
            <h2>Login</h2>
            
            <div className="input-container">
                <Person className="input-icon" />
                <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={handleUsernameChange}
                    className="auth-input"
                    required
                />
            </div>

            <div className="input-container">
                <Lock className="input-icon" />
                <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    value={password}
                    onChange={handlePasswordChange}
                    onKeyDown={handleKeyDown}
                    onKeyUp={handleKeyUp}
                    className="auth-input"
                    required
                />
                <button
                    type="button"
                    className="visibility-toggle"
                    onClick={() => setShowPassword(!showPassword)}
                    tabIndex="-1"
                >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                </button>
            </div>

            {renderCapsLockWarning(isCapsLockOn)}

            <div className="auth-links">
                <button
                    type="button"
                    className="link-button"
                    onClick={() => navigate('/reset-password')}
                >
                    Forgot Password?
                </button>
                <button 
                    type="button"
                    className="link-button"
                    onClick={() => navigate('/change-password')}
                >
                    Change Password
                </button>
            </div>

            <button type="submit" className="submit-button">
                Login
            </button>

            <div className="auth-switch">
                Don't have an account?{' '}
                <button type="button" onClick={() => navigate('/register')}>Register</button>
            </div>
        </form>
    );
}
