import React, { useState, useEffect, useCallback, useRef, memo } from 'react';
import axios from 'axios';
import { Send as SendIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';

// Create a separate memoized reply preview component
const ReplyPreview = memo(({ replyTo, currentChat, onCancelReply }) => {
    if (!replyTo) return null;
    
    return (
        <div className="reply-preview-container">
            <div className="reply-preview-content">
                <div className="reply-preview-author">
                    Replying to {replyTo.sender_id === currentChat?.id ? currentChat.username : 'yourself'}
                </div>
                <div className="reply-preview-text">
                    {replyTo.content}
                </div>
            </div>
            <button 
                className="reply-preview-close"
                onClick={onCancelReply}
                aria-label="Cancel reply"
            >
                ×
            </button>
        </div>
    );
}, (prevProps, nextProps) => {
    return (
        prevProps.replyTo?.id === nextProps.replyTo?.id &&
        prevProps.currentChat?.id === nextProps.currentChat?.id
    );
});

const MessageInput = ({
    onSend,
    onTyping,
    currentChat,
    replyTo,
    onCancelReply,
    isTypingMessage
}) => {
    const [message, setMessage] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const inputRef = useRef(null);
    const typingTimeoutRef = useRef(null);
    
    // Clear message when changing chats
    useEffect(() => {
        setMessage('');
        if (currentChat?.id) {
            fetchDraft();
        }

        if (inputRef.current) {
            inputRef.current.focus();
            inputRef.current.setSelectionRange(message.length, message.length);
        }
        
        return () => {
            if (typingTimeoutRef.current) {
                clearTimeout(typingTimeoutRef.current);
            }
            if (isTyping) {
                onTyping(false);
            }
        };
    }, [currentChat?.id]);

    const fetchDraft = async () => {
        if (!currentChat?.id) return;
        
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`/api/drafts/${currentChat.id}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            
            if (response.data.content && response.data.content.trim()) {
                setMessage(response.data.content.trim());
            }
        } catch (error) {
            console.error('Error fetching draft:', error);
        }
    };

    const saveDraft = useCallback(
        async (content) => {
            if (!currentChat?.id) return;
            
            setIsSaving(true);
            try {
                const token = localStorage.getItem('token');
                await axios.post(
                    `/api/drafts/${currentChat.id}`,
                    { content },
                    { headers: { Authorization: `Bearer ${token}` }}
                );
            } catch (error) {
                console.error('Error saving draft:', error);
            } finally {
                setIsSaving(false);
            }
        },
        [currentChat?.id]
    );

    const deleteDraft = async () => {
        if (!currentChat?.id) return;
        
        try {
            const token = localStorage.getItem('token');
            await axios.delete(`/api/drafts/${currentChat.id}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
        } catch (error) {
            console.error('Error deleting draft:', error);
        }
    };

    // Handle typing status with shorter timeout
    const handleTypingStatus = (isCurrentlyTyping) => {
        if (typingTimeoutRef.current) {
            clearTimeout(typingTimeoutRef.current);
        }

        if (!isTyping && isCurrentlyTyping) {
            setIsTyping(true);
            onTyping(true);
        }

        typingTimeoutRef.current = setTimeout(() => {
            setIsTyping(false);
            onTyping(false);
        }, 1000); // Reduced to 1 second
    };

    const handleChange = (e) => {
        const content = e.target.value;
        setMessage(content);
        
        // Handle typing status
        handleTypingStatus(content.length > 0);
        
        // Save or delete draft
        if (content.trim()) {
            saveDraft(content);
        } else {
            deleteDraft();
        }
    };

    const handleKeyDown = async (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            if (message.trim()) {
                onSend(message);
                setMessage('');
                await deleteDraft();
                handleTypingStatus(false);
            }
        }
    };

    // Focus input when replying
    useEffect(() => {
        if (replyTo && inputRef.current) {
            inputRef.current.focus();
        }
    }, [replyTo]);

    // Handle Escape key to cancel reply
    useEffect(() => {
        const handleEscape = (e) => {
            if (e.key === 'Escape' && replyTo) {
                onCancelReply();
            }
        };

        document.addEventListener('keydown', handleEscape);
        return () => document.removeEventListener('keydown', handleEscape);
    }, [replyTo, onCancelReply]);

    const handleSend = async () => {
        if (!message.trim()) return;

        try {
            await onSend(message);
            setMessage('');
            await deleteDraft();
            handleTypingStatus(false);
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    return (
        <div className="message-input-container">
            <ReplyPreview 
                replyTo={replyTo}
                currentChat={currentChat}
                onCancelReply={onCancelReply}
            />
            <div className="message-input">
                <textarea
                    ref={inputRef}
                    value={message}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    placeholder={replyTo ? "Type your reply..." : "Type a message..."}
                    rows="1"
                />
                {isSaving && <div className="draft-saving-indicator">Saving...</div>}
                <IconButton 
                    type="submit" 
                    color="primary" 
                    onClick={handleSend}
                    disabled={!message.trim()}
                    sx={{ 
                        ml: 1,
                        opacity: !message.trim() ? 0.3 : 1,
                        '&.Mui-disabled': {
                            opacity: 0.3
                        }
                    }}
                >
                    <SendIcon />
                </IconButton>
            </div>
        </div>
    );
};

export default memo(MessageInput);