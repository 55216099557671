// src/components/RegisterForm.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff, Person, Email, Lock, ArrowBack } from '@mui/icons-material';
import { validatePassword, getPasswordStrengthColor, renderCapsLockWarning } from '../utils/passwordUtils';
import axios from 'axios';
import toast from 'react-hot-toast';
import Notifications from './Notifications';

export default function RegisterForm() {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isCapsLockOn, setIsCapsLockOn] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState({ score: 0, message: '', requirements: {}, isStrong: false });
    const [error, setError] = useState('');
    const [isReturningUser, setIsReturningUser] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const handleKeyDown = (e) => {
            setIsCapsLockOn(e.getModifierState('CapsLock'));
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, []);

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        setPasswordStrength(validatePassword(newPassword, confirmPassword));
    };

    const handleConfirmPasswordChange = (e) => {
        const newConfirmPassword = e.target.value;
        setConfirmPassword(newConfirmPassword);
        setPasswordStrength(validatePassword(password, newConfirmPassword));
    };

    const handleKeyDown = (e) => {
        setIsCapsLockOn(e.getModifierState('CapsLock'));
    };

    const handleKeyUp = (e) => {
        setIsCapsLockOn(e.getModifierState('CapsLock'));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setIsLoading(true);

        try {
            if (isReturningUser) {
                const response = await fetch('/api/register/check-deleted-account', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ email }),
                });

                if (!response.ok) {
                    const text = await response.text();
                    try {
                        const data = JSON.parse(text);
                        throw new Error(data.detail || 'Error checking account');
                    } catch (parseError) {
                        throw new Error('Server error: ' + text);
                    }
                }

                const data = await response.json();

                if (data.user) {
                    navigate('/restore-account', {
                        state: {
                            deletedUser: data.user,
                            registrationData: { username, email, password }
                        }
                    });
                    return;
                }

                setError('No deleted account found with this email.');
                return;
            }

            // Regular registration flow
            const registrationData = {
                username,
                email,
                password
            };

            const response = await fetch('/api/register', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(registrationData),
            });

            const data = await response.json();

            if (data.status === 'deleted_account_exists') {
                navigate('/restore-account', {
                    state: {
                        deletedUser: data.deleted_user,
                        registrationData: registrationData
                    }
                });
                return;
            }

            if (!response.ok) {
                throw new Error(data.detail || 'Registration failed');
            }

            // Handle successful registration
            navigate('/login', { 
                state: { message: 'Registration successful. Please login.' }
            });
        } catch (error) {
            toast.error(error.message || 'Error checking account status');
            setError(error.message || 'Error checking account status');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="auth-form-container">
            <Notifications />
            <button
                className="back-button"
                onClick={() => navigate('/login')}
            >
                <ArrowBack /> Back
            </button>

            {error && <div className="error-message">{error}</div>}

            <form className="auth-form" onSubmit={handleSubmit}>
                <div className="returning-user-toggle">
                    <label className="checkbox-label">
                        <input
                            type="checkbox"
                            checked={isReturningUser}
                            onChange={(e) => setIsReturningUser(e.target.checked)}
                            className="checkbox-input"
                        />
                        <span className="checkbox-text">I had an account before</span>
                    </label>
                </div>

                {isReturningUser ? (
                    <div className="input-container">
                        <Email className="input-icon" />
                        <input
                            type="email"
                            placeholder="Enter your previous email address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="auth-input"
                            required
                        />
                    </div>
                ) : (
                    <>
                        <div className="input-container">
                            <Person className="input-icon" />
                            <input
                                type="text"
                                placeholder="Username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                className="auth-input"
                                required
                            />
                        </div>

                        <div className="input-container">
                            <Email className="input-icon" />
                            <input
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="auth-input"
                                required
                            />
                        </div>

                        <div className="input-container">
                            <Lock className="input-icon" />
                            <input
                                type={showPassword ? "text" : "password"}
                                placeholder="Password"
                                value={password}
                                onChange={handlePasswordChange}
                                onKeyDown={handleKeyDown}
                                onKeyUp={handleKeyUp}
                                className="auth-input"
                                required
                            />
                            <button
                                type="button"
                                className="visibility-toggle"
                                onClick={() => setShowPassword(!showPassword)}
                                tabIndex="-1"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </button>
                        </div>

                        <div className="input-container">
                            <Lock className="input-icon" />
                            <input
                                type={showConfirmPassword ? "text" : "password"}
                                placeholder="Confirm Password"
                                value={confirmPassword}
                                onChange={handleConfirmPasswordChange}
                                onKeyDown={handleKeyDown}
                                onKeyUp={handleKeyUp}
                                className="auth-input"
                                required
                            />
                            <button
                                type="button"
                                className="visibility-toggle"
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                tabIndex="-1"
                            >
                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                            </button>
                        </div>

                        {renderCapsLockWarning(isCapsLockOn)}

                        {password && (
                            <div className="password-strength-container">
                                <div className="password-strength-bar">
                                    <div 
                                        className="password-strength-progress"
                                        style={{
                                            width: `${(passwordStrength.score / 6) * 100}%`,
                                            backgroundColor: getPasswordStrengthColor(passwordStrength.score)
                                        }}
                                    />
                                </div>
                                <div className="password-strength-text">
                                    <span className="strength-label">Password Strength:</span>
                                    <span 
                                        className="strength-message" 
                                        style={{ color: getPasswordStrengthColor(passwordStrength.score) }}
                                    >
                                        {passwordStrength.message}
                                    </span>
                                </div>
                                <div className="password-requirements">
                                    <div className={passwordStrength.requirements.length ? 'met' : ''}>
                                        • At least 8 characters
                                    </div>
                                    <div className={passwordStrength.requirements.uppercase ? 'met' : ''}>
                                        • At least one uppercase letter
                                    </div>
                                    <div className={passwordStrength.requirements.lowercase ? 'met' : ''}>
                                        • At least one lowercase letter
                                    </div>
                                    <div className={passwordStrength.requirements.number ? 'met' : ''}>
                                        • At least one number
                                    </div>
                                    <div className={passwordStrength.requirements.special ? 'met' : ''}>
                                        • At least one special character
                                    </div>
                                    <div className={passwordStrength.requirements.match ? 'met' : ''}>
                                        • Passwords match
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}

                <button
                    type="submit"
                    disabled={isReturningUser ? !email : (!username || !email || !password || !confirmPassword || !passwordStrength.isStrong)}
                    className="submit-button"
                >
                    {isReturningUser ? 'Check Previous Account' : 'Register'}
                </button>
            </form>
        </div>
    );
}
