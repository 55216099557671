import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

const useContactDraft = (contactId) => {
    const [draft, setDraft] = useState('');
    const [loading, setLoading] = useState(false);

    const fetchDraft = useCallback(async () => {
        if (!contactId) return;

        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`/api/drafts/${contactId}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            
            // Only set draft if there's actual content
            const content = response.data.content;
            if (content && content.trim()) {
                setDraft(content.trim());
            } else {
                setDraft('');
            }
            
        } catch (error) {
            console.error('Error fetching draft:', error);
            setDraft('');
        } finally {
            setLoading(false);
        }
    }, [contactId]);

    // Poll for draft updates
    useEffect(() => {
        fetchDraft();
        
        const pollInterval = setInterval(fetchDraft, 1000);
        
        return () => {
            clearInterval(pollInterval);
        };
    }, [contactId, fetchDraft]);

    // Reset draft when contactId changes
    useEffect(() => {
        setDraft('');
    }, [contactId]);

    return { draft, loading, refetchDraft: fetchDraft };
};

export default useContactDraft;