import Logger from '../services/logger';

// Utility Functions
export const getInitialTheme = () => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme === 'dark' ||
           (!savedTheme && window.matchMedia('(prefers-color-scheme: dark)').matches);
};

export const formatTime = (timestamp) => {
    if (!timestamp) return '';
    try {
        const date = new Date(timestamp);
        return date.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });
    } catch (error) {
        Logger.error('Error formatting time:', error);
        return '';
    }
};

export const formatTimestampToDate = (timestamp) => {
    const date = new Date(timestamp);
    const today = new Date();
    const diffTime = Math.abs(today - date);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    // Check if the date is today
    if (date.toDateString() === today.toDateString()) {
        // If today, return time in HH:MM format
        return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
    } else if (diffDays < 7) {
        // If within 1 week, return the day of the week
        return date.toLocaleDateString([], { weekday: 'long' });
    }
    
    // Format date as dd/mm/yyyy for dates older than a week
    return `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`;
};

export const formatLastSeen = (timestamp) => {
    if (!timestamp) return '';

    try {
        const date = new Date(timestamp);
        const now = new Date();
        const diffTime = Math.abs(now - date);
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays === 0) {
            return `at ${date.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
            })}`;
        } else if (diffDays === 1) {
            return `yesterday at ${date.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
            })}`;
        } else if (diffDays < 7) {
            return `${date.toLocaleDateString([], { weekday: 'long' })} at ${date.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
            })}`;
        } else {
            return date.toLocaleDateString([], {
                month: 'short',
                day: 'numeric',
                year: date.getFullYear() !== now.getFullYear() ? 'numeric' : undefined
            });
        }
    } catch (error) {
        Logger.error('Error formatting last seen:', error);
        return '';
    }
};

export const groupMessagesByDate = (messages) => {
    const groups = [];
    let currentGroup = null;
    let currentDate = null;

    messages.forEach(message => {
        const messageDate = new Date(message.timestamp);
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);

        let dateStr;
        const diffTime = Math.abs(today - messageDate);
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays === 0) {
            dateStr = 'Today';
        } else if (diffDays === 1) {
            dateStr = 'Yesterday';
        } else if (diffDays < 7) {
            dateStr = messageDate.toLocaleDateString('en-US', {
                weekday: 'short'
            });
        } else {
            dateStr = messageDate.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            });
        }

        if (dateStr !== currentDate) {
            if (currentGroup) {
                groups.push(currentGroup);
            }
            currentGroup = {
                date: dateStr,
                timestamp: messageDate.getTime(),
                messages: []
            };
            currentDate = dateStr;
        }

        currentGroup.messages.push(message);
    });

    if (currentGroup) {
        groups.push(currentGroup);
    }

    return groups;
};

export const sortMessages = (messages) => {
    if (!messages || !Array.isArray(messages)) return [];
    
    // Remove duplicates based on message ID
    const uniqueMessages = Array.from(
        new Map(messages.map(msg => [msg.id, msg])).values()
    );

    // Sort by sequence and timestamp
    return uniqueMessages.sort((a, b) => {
        if (a.sequence !== b.sequence) {
            return a.sequence - b.sequence;
        }
        // return new Date(a.timestamp) - new Date(b.timestamp);
    });
};

export const getUnreadCount = (messages, userId) => {
    if (!messages || !Array.isArray(messages)) return 0;
    return messages.filter(msg =>
        msg.sender_id !== userId && !msg.read
    ).length;
};

export const processMessages = (messages) => {
    const sortedMessages = sortMessages(messages);
    return groupMessagesByDate(sortedMessages);
};