// TypingIndicator.js
import React, { memo } from 'react';

const TypingIndicator = memo(({ isTyping, username }) => {
    if (!isTyping) return null;
    
    return (
        <div className="typing-indicator">
            <span className="typing-status">{username} is typing...</span>
        </div>
    );
}, (prevProps, nextProps) => {
    return prevProps.isTyping === nextProps.isTyping;
});

export default TypingIndicator;