export const checkPasswordMatch = (password, confirmPassword) => {
    return password === confirmPassword;
};

export const validatePassword = (password, confirmPassword = null) => {
    const requirements = {
        length: password.length >= 8,
        uppercase: /[A-Z]/.test(password),
        lowercase: /[a-z]/.test(password),
        number: /\d/.test(password),
        special: /[!@#$%^&*(),.?":{}|<>]/.test(password),
        match: confirmPassword === null ? true : password === confirmPassword
    };

    const score = Object.values(requirements).filter(Boolean).length;
    const isStrong = score >= 5 && (confirmPassword === null || requirements.match);

    let message = 'Very Weak';
    if (score === 6) message = 'Very Strong';
    else if (score === 5) message = 'Strong';
    else if (score === 4) message = 'Moderate';
    else if (score === 3) message = 'Weak';
    else if (score <= 2) message = 'Very Weak';

    return { score, message, requirements, isStrong };
};

export const getPasswordStrengthColor = (score) => {
    const colors = {
        0: '#ff4444',
        1: '#ff8c1a',
        2: '#ffd700',
        3: '#90ee90',
        4: '#32cd32',
        5: '#008000'
    };
    return colors[score] || colors[0];
};

export const getPasswordStrengthPercentage = (score) => {
    return (score / 5) * 100;
};

export const useCapsLockDetection = (e) => {
    if (e.getModifierState) {
        return e.getModifierState('CapsLock');
    }
    return false;
};

export const renderCapsLockWarning = (isCapsLockOn) => {
    if (!isCapsLockOn) return null;
    
    return (
        <div className="caps-lock-warning">
            <span>⚠️ Caps Lock is ON</span>
        </div>
    );
}; 