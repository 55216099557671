// src/services/logger.js

class Logger {
    static levels = {
        ERROR: 'ERROR',
        WARN: 'WARN',
        INFO: 'INFO',
        DEBUG: 'DEBUG'
    };

    static log(level, message, data = null) {
        const timestamp = new Date().toISOString();
        const source = 'FRONTEND';
        const logData = {
            timestamp,
            source,
            level,
            message,
            data: data ? this.sanitizeData(data) : null,
            app: 'whatsapp-clone',
            component: this.getComponentFromStack(),
            session_id: localStorage.getItem('adminToken') ? 'admin' : 'user'
        };
        
        // Console logging with source
        switch (level) {
            case this.levels.ERROR:
                console.error(`[${source}] ${timestamp}:`, message, data);
                break;
            case this.levels.WARN:
                console.warn(`[${source}] ${timestamp}:`, message, data);
                break;
            case this.levels.INFO:
                console.info(`[${source}] ${timestamp}:`, message, data);
                break;
            case this.levels.DEBUG:
                console.debug(`[${source}] ${timestamp}:`, message, data);
                break;
            default:
                console.log(`[${source}] ${timestamp}:`, message, data);
        }

        // Send to server if configured
        if (process.env.REACT_APP_LOG_TO_SERVER === 'true') {
            this.sendToServer(logData);
        }
    }

    static getComponentFromStack() {
        try {
            const stack = new Error().stack;
            const stackLines = stack.split('\n');
            // Find the first line that's not from logger.js
            const componentLine = stackLines.find(line => !line.includes('logger.js'));
            if (componentLine) {
                const match = componentLine.match(/at\s+(\w+)/);
                return match ? match[1] : 'unknown';
            }
            return 'unknown';
        } catch (error) {
            return 'unknown';
        }
    }

    static sanitizeData(data) {
        if (data instanceof Error) {
            return {
                error: data.message,
                stack: data.stack,
                source: 'FRONTEND'
            };
        }

        // If data is from backend response
        if (data?.source === 'BACKEND') {
            return {
                ...data,
                timestamp: new Date().toISOString()
            };
        }

        // Add source to all other data
        return {
            ...data,
            source: 'FRONTEND',
            timestamp: new Date().toISOString()
        };
    }

    static error(message, data = null) {
        this.log(this.levels.ERROR, message, data);
    }

    static warn(message, data = null) {
        this.log(this.levels.WARN, message, data);
    }

    static info(message, data = null) {
        this.log(this.levels.INFO, message, data);
    }

    static debug(message, data = null) {
        this.log(this.levels.DEBUG, message, data);
    }

    static logBackendError(error) {
        const backendData = {
            source: 'BACKEND',
            error: error.response?.data?.detail || error.message,
            status: error.response?.status,
            endpoint: error.config?.url,
            method: error.config?.method,
            timestamp: new Date().toISOString()
        };
        this.error('Backend error occurred', backendData);
    }

    static formatLogEntry(logEntry) {
        return {
            timestamp: logEntry.timestamp,
            level: logEntry.level,
            message: logEntry.message,
            source: logEntry.source,
            app: logEntry.app,
            component: logEntry.component,
            session_id: logEntry.session_id,
            metadata: {
                ...logEntry.data,
                user_agent: navigator.userAgent,
                platform: navigator.platform,
                url: window.location.href
            }
        };
    }

    static async sendToServer(logEntry) {
        if (!process.env.REACT_APP_LOG_ENDPOINT) return;

        const token = localStorage.getItem('token') || localStorage.getItem('adminToken');
        if (!token) return;

        try {
            const formattedLog = this.formatLogEntry(logEntry);
            const response = await fetch(process.env.REACT_APP_LOG_ENDPOINT, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(formattedLog)
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('[FRONTEND] Failed to send log to server:', {
                    status: response.status,
                    error: errorData,
                    logEntry: formattedLog
                });
            }
        } catch (error) {
            console.error('[FRONTEND] Failed to send log to server:', {
                error,
                logEntry
            });
        }
    }
}

export default Logger;