import React, { useRef, useCallback, useEffect, useState, useMemo } from 'react';
import { VariableSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { groupMessagesByDate } from './UtilityFunc';  // Use the existing utility function

const MessageRow = React.memo(({ data, index, style }) => {
    const {
        groupedMessages,
        renderMessage,
        messageProps,
        measureRef,
        heightCache,
        resetListAfterIndex
    } = data;
    
    // Find which group and message this index corresponds to
    let currentIndex = 0;
    let targetGroup = null;
    let messageIndexInGroup = 0;
    let isDateHeader = false;
    let dateStr = '';

    for (const group of groupedMessages) {
        if (index === currentIndex) {
            isDateHeader = true;
            dateStr = group.date;
            break;
        }
        currentIndex++;
        
        if (index - currentIndex < group.messages.length) {
            targetGroup = group;
            messageIndexInGroup = index - currentIndex;
            break;
        }
        currentIndex += group.messages.length;
    }

    const message = targetGroup ? targetGroup.messages[messageIndexInGroup] : null;
    const messageRef = useRef(null);
    const [forceUpdate, setForceUpdate] = useState(0);

    const updateHeight = useCallback(() => {
        if (!messageRef.current) return;

        void messageRef.current.offsetHeight;

        let totalHeight = 0;

        if (isDateHeader) {
            const header = messageRef.current.querySelector('.message-date-header');
            if (header) {
                totalHeight = header.getBoundingClientRect().height + 16;
            }
        } else {
            const messageBubble = messageRef.current.querySelector('.message-bubble');
            const editForm = messageRef.current.querySelector('.edit-message-form');
            const deleteConfirm = messageRef.current.querySelector('.delete-confirmation');

            if (messageBubble) {
                // Get total height from message bubble which includes reply preview if present
                totalHeight = messageBubble.getBoundingClientRect().height;
                
                // Check for edit form or delete confirmation which might be taller
                if (editForm) totalHeight = Math.max(totalHeight, editForm.getBoundingClientRect().height);
                if (deleteConfirm) totalHeight = Math.max(totalHeight, deleteConfirm.getBoundingClientRect().height);
                
                // Add space for reactions if present
                if (messageProps.messageReactions[message?.id] && 
                    Object.keys(messageProps.messageReactions[message?.id]).length > 0) {
                    totalHeight += 16;
                }
                
                // Add minimal padding
                totalHeight += 2;
                
                if (messageProps.messageReactions[message?.id] && 
                    Object.keys(messageProps.messageReactions[message?.id]).length > 0) {
                    totalHeight += 16;
                }
                
                totalHeight += 4;
            }
        }

        const cacheKey = isDateHeader ? `header-${index}` : message?.id;
        if (heightCache.current[cacheKey] !== totalHeight) {
            heightCache.current[cacheKey] = totalHeight;
            resetListAfterIndex(index);
            
            if (measureRef && message) {
                measureRef(message.id, totalHeight);
            }
            
            requestAnimationFrame(() => {
                setForceUpdate(prev => prev + 1);
            });
        }
    }, [isDateHeader, message, messageProps, index, measureRef, resetListAfterIndex, heightCache]);

    useEffect(() => {
        if (!messageRef.current) return;

        const mutationObserver = new MutationObserver(() => {
            updateHeight();
            requestAnimationFrame(updateHeight);
            setTimeout(updateHeight, 50);
        });

        mutationObserver.observe(messageRef.current, {
            childList: true,
            subtree: true,
            attributes: true,
            characterData: true
        });

        updateHeight();

        return () => mutationObserver.disconnect();
    }, [message, updateHeight]);

    if (isDateHeader) {
        return (
            <div ref={messageRef} style={style} className="message-date-header">
                <span>{dateStr}</span>
            </div>
        );
    }

    if (!message) return null;

    return (
        <div ref={messageRef} style={style} key={forceUpdate}>
            {renderMessage({ message, style: {}, index: messageIndexInGroup, ...messageProps })}
        </div>
    );
});

const StableMessageList = React.memo(({
    messages,
    messageHeights,
    onScroll,
    listRef,
    renderMessage,
    messageProps,
    containerHeight
}) => {
    const [isInitialScroll, setIsInitialScroll] = useState(true);
    const heightCache = useRef({});
    const lastRenderedMessages = useRef(messages);
    const initialScrollComplete = useRef(false);
    const resizeObserver = useRef(null);
    const containerRef = useRef(null);

    // Use the existing groupMessagesByDate function
    const groupedMessages = useMemo(() => {
        return groupMessagesByDate(messages);
    }, [messages]);

    // Reset cache when chat changes
    useEffect(() => {
        if (messages.length === 0 || 
            (lastRenderedMessages.current.length > 0 && 
             messages[0]?.sender_id !== lastRenderedMessages.current[0]?.sender_id)) {
            heightCache.current = {};
            initialScrollComplete.current = false;
            setIsInitialScroll(true);
            if (listRef.current) {
                listRef.current.resetAfterIndex(0);
            }
        }
        lastRenderedMessages.current = messages;
    }, [messages, listRef]);

    // Calculate total number of items (date headers + messages)
    const totalItems = useMemo(() => {
        return groupedMessages.reduce((sum, group) => sum + 1 + group.messages.length, 0);
    }, [groupedMessages]);

    const getItemSize = useCallback((index) => {
        let currentIndex = 0;
        
        for (const group of groupedMessages) {
            if (index === currentIndex) {
                return heightCache.current[`header-${index}`] || 40;
            }
            currentIndex++;
            
            if (index - currentIndex < group.messages.length) {
                const message = group.messages[index - currentIndex];
                return heightCache.current[message.id] || 
                       messageHeights.current[message.id] || 
                       40;
            }
            currentIndex += group.messages.length;
        }
        
        return 40;
    }, [groupedMessages, messageHeights]);

    const getInitialScrollOffset = useCallback(() => {
        if (!totalItems) return 0;
        
        let totalHeight = 0;
        for (let i = 0; i < totalItems; i++) {
            totalHeight += getItemSize(i);
        }
        
        const listHeight = containerHeight || 0;
        return Math.max(0, totalHeight - listHeight + 100);
    }, [totalItems, getItemSize, containerHeight]);

    // Handle container resizing
    useEffect(() => {
        if (!containerRef.current) return;

        resizeObserver.current = new ResizeObserver(() => {
            if (listRef.current) {
                listRef.current.resetAfterIndex(0);
                if (isInitialScroll) {
                    requestAnimationFrame(() => {
                        const lastIndex = totalItems - 1;
                        if (lastIndex >= 0) {
                            listRef.current.scrollToItem(lastIndex, 'end');
                        }
                    });
                }
            }
        });

        resizeObserver.current.observe(containerRef.current);

        return () => {
            if (resizeObserver.current) {
                resizeObserver.current.disconnect();
            }
        };
    }, [totalItems, isInitialScroll, listRef]);

    // Handle initial scroll
    useEffect(() => {
        if (isInitialScroll && totalItems > 0 && containerHeight > 0) {
            requestAnimationFrame(() => {
                if (listRef.current) {
                    const lastIndex = totalItems - 1;
                    listRef.current.scrollToItem(lastIndex, 'end');
                    setIsInitialScroll(false);
                }
            });
        }
    }, [totalItems, containerHeight, isInitialScroll, listRef]);

    return (
        <div ref={containerRef} style={{ height: containerHeight || '100%', width: '100%' }}>
            <AutoSizer>
                {({ height, width }) => (
                    <VariableSizeList
                        ref={listRef}
                        width={width}
                        height={height}
                        itemCount={totalItems}
                        itemSize={getItemSize}
                        initialScrollOffset={getInitialScrollOffset()}
                        itemData={{
                            groupedMessages,
                            renderMessage,
                            messageProps,
                            measureRef: (messageId, height) => {
                                messageHeights.current[messageId] = height;
                            },
                            heightCache,
                            resetListAfterIndex: (index) => {
                                if (listRef.current) {
                                    listRef.current.resetAfterIndex(index, true);
                                }
                            }
                        }}
                        onScroll={onScroll}
                        style={{
                            overflowX: 'hidden',
                            willChange: 'transform'
                        }}
                    >
                        {MessageRow}
                    </VariableSizeList>
                )}
            </AutoSizer>
        </div>
    );
});

export default StableMessageList;