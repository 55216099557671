import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Logger from '../services/logger';
import { toast } from 'react-hot-toast';
import { Transition } from '@headlessui/react';
import Notifications from './Notifications';

const SettingsMenu = ({ onClose, user }) => {
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [deleteType, setDeleteType] = useState(null);
    const navigate = useNavigate();

    // Add escape key handler
    useEffect(() => {
        const handleEscape = (e) => {
            if (e.key === 'Escape') {
                if (showDeleteConfirm) {
                    setShowDeleteConfirm(false);
                    setDeleteType(null);
                } else {
                    onClose();
                }
            }
        };

        window.addEventListener('keydown', handleEscape);
        return () => window.removeEventListener('keydown', handleEscape);
    }, [onClose, showDeleteConfirm]);

    const handleDeleteAccount = async (keepData) => {
        try {
            const token = localStorage.getItem('token');
            
            Logger.info('Attempting to delete account', { 
                userId: user.id,
                username: user.username,
                keepData,
                source: 'FRONTEND'
            });

            await axios.delete(
                `/api/users/${user.id}/delete`,
                {
                    data: { 
                        keep_data: keepData ? 1 : 0 
                    },
                    headers: { 
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            
            toast.success('Account deleted successfully');

            Logger.info('Successfully deleted account', {
                userId: user.id,
                keepData: keepData,
                source: 'FRONTEND'
            });
    
            // Close settings menu before navigation
            onClose();
            
            // Navigate to login page
            navigate('/login');
    
            // Clear user data after navigation is initiated
            localStorage.clear();
            sessionStorage.clear();
        } catch (error) {
            Logger.error('Failed to delete account:', {
                error: error.message,
                userId: user.id,
                username: user.username,
                keepData,
                source: 'FRONTEND'
            });
            toast.error('Failed to delete account. Please try again.');
        }
    };

    return (
        <Transition
            show={true}
            enter="transform transition ease-in-out duration-300"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-300"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
        >
            <div className="fixed right-0 top-0 h-screen w-[480px] bg-white dark:bg-gray-800 shadow-2xl z-50 overflow-y-auto">
                <div className="min-h-screen flex flex-col">
                    <Notifications />
                    {/* Header */}
                    <div className="flex justify-between items-center p-8 border-b border-gray-200 dark:border-gray-700 sticky top-0 bg-white dark:bg-gray-800 z-10">
                        <h3 className="text-2xl font-semibold text-gray-800 dark:text-gray-200">Settings</h3>
                        <button 
                            onClick={onClose}
                            className="p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
                        >
                            <svg className="w-6 h-6 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>

                    {/* Content */}
                    <div className="flex-1 p-8 space-y-8">
                        {/* Account Section */}
                        <div>
                            <h4 className="text-xl font-medium text-gray-800 dark:text-gray-200 mb-6">Account</h4>
                            <div className="space-y-4">
                                {/* Delete Account Button */}
                                <button 
                                    onClick={() => setShowDeleteConfirm(true)}
                                    className="w-full flex items-center justify-between px-4 py-3 bg-red-50 dark:bg-red-900/30 text-red-700 dark:text-red-300 rounded-lg hover:bg-red-100 dark:hover:bg-red-900/50 transition-colors"
                                >
                                    <span className="flex items-center">
                                        <svg className="w-5 h-5 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                        </svg>
                                        Delete Account
                                    </span>
                                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Delete Confirmation Dialog */}
                    <Transition
                        show={showDeleteConfirm}
                        enter="transition-opacity ease-in-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-in-out duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/50 z-50">
                            <div className="min-h-screen px-4 text-center">
                                <div className="fixed inset-0" onClick={() => setShowDeleteConfirm(false)} />
                                
                                <div className="inline-block align-middle my-8 p-8 w-full max-w-2xl text-left bg-white dark:bg-gray-800 rounded-2xl shadow-xl transform transition-all">
                                    <h3 className="text-2xl font-semibold text-gray-900 dark:text-gray-100 mb-6">
                                        Delete Account
                                    </h3>
                                    
                                    {!deleteType ? (
                                        <div className="space-y-4">
                                            <button
                                                onClick={() => setDeleteType('keep')}
                                                className="w-full flex items-center justify-between p-4 bg-gray-50 dark:bg-gray-700 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-600 transition-colors"
                                            >
                                                <div className="flex items-center">
                                                    <svg className="w-5 h-5 mr-3 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4" />
                                                    </svg>
                                                    Keep my data
                                                </div>
                                                <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                                </svg>
                                            </button>
                                            
                                            <button
                                                onClick={() => setDeleteType('delete')}
                                                className="w-full flex items-center justify-between p-4 bg-red-50 dark:bg-red-900/30 text-red-700 dark:text-red-300 rounded-lg hover:bg-red-100 dark:hover:bg-red-900/50 transition-colors"
                                            >
                                                <div className="flex items-center">
                                                    <svg className="w-5 h-5 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                    </svg>
                                                    Delete all data
                                                </div>
                                                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                                                </svg>
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="space-y-4">
                                            <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
                                                <p className="text-gray-600 dark:text-gray-300">
                                                    {deleteType === 'keep' 
                                                        ? 'Your account will be deleted but your messages will be preserved.'
                                                        : 'Your account and all your messages will be permanently deleted.'}
                                                </p>
                                            </div>
                                            
                                            <div className="flex space-x-3">
                                                <button
                                                    onClick={() => handleDeleteAccount(deleteType === 'keep')}
                                                    className="flex-1 px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-colors"
                                                >
                                                    Confirm
                                                </button>
                                                <button
                                                    onClick={() => setDeleteType(null)}
                                                    className="flex-1 px-4 py-2 bg-gray-200 dark:bg-gray-600 text-gray-800 dark:text-gray-200 rounded-lg hover:bg-gray-300 dark:hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition-colors"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        </Transition>
    );
};

export default SettingsMenu; 