import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Logger from '../services/logger';
import { useTheme } from '../contexts/ThemeContext';
import { toast } from 'react-hot-toast';
import {
  EyeIcon,
  EyeSlashIcon,
  ArrowLeftIcon,
  SunIcon,
  MoonIcon,
  ShieldCheckIcon,
  ExclamationCircleIcon
} from '@heroicons/react/24/outline';

const AdminLoginTailwind = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isCapsLockOn, setIsCapsLockOn] = useState(false);
  const navigate = useNavigate();
  const { theme, toggleTheme } = useTheme();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      Logger.info('Admin login attempt', { username, source: 'FRONTEND' });
      const response = await axios.post('/api/admin/login', {
        username,
        password,
      });

      const token = response.data.access_token;
      if (!token) {
        Logger.error('Admin login failed: Invalid response from server', { 
          username,
          source: 'BACKEND',
          response: response.data 
        });
        toast.error('Invalid response from server');
        return;
      }

      localStorage.setItem('adminToken', token);
      Logger.info('Admin login successful', { 
        username,
        source: 'FRONTEND'
      });
      toast.success('Login successful');
      navigate('/admin/dashboard');
    } catch (error) {
      Logger.error('Admin login failed', { 
        username,
        error: error.message,
        source: 'FRONTEND'
      });
      toast.error(error.response?.data?.detail || 'Login failed');
    }
  };

  const handleKeyDown = (e) => {
    setIsCapsLockOn(e.getModifierState('CapsLock'));
  };

  const handleKeyUp = (e) => {
    setIsCapsLockOn(e.getModifierState('CapsLock'));
  };

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="min-h-screen flex items-center justify-center bg-gray-50 dark:bg-gray-900 relative">
        {/* Back Button */}
        <button
          onClick={() => navigate('/login')}
          className="absolute top-4 left-4 p-2 text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-full transition-colors duration-200"
        >
          <ArrowLeftIcon className="h-6 w-6" />
        </button>

        {/* Theme Toggle */}
        <button
          onClick={toggleTheme}
          className="absolute top-4 right-4 p-2 text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-full transition-colors duration-200"
        >
          {theme === 'dark' ? (
            <SunIcon className="h-6 w-6" />
          ) : (
            <MoonIcon className="h-6 w-6" />
          )}
        </button>

        {/* Main Container */}
        <div className="w-full max-w-md p-8 mx-4">
          <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-xl p-8 space-y-6 
            border border-gray-200 dark:border-gray-700
            transform transition-all duration-300 hover:shadow-2xl">
            
            {/* Logo and Title */}
            <div className="text-center space-y-4">
              <div className="flex justify-center">
                <div className="p-4 bg-blue-50 dark:bg-blue-900/20 rounded-full">
                  <ShieldCheckIcon className="h-12 w-12 text-blue-500 dark:text-blue-400" />
                </div>
              </div>
              <div>
                <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
                  Admin Portal
                </h2>
                <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                  Enter your credentials to continue
                </p>
              </div>
            </div>

            {/* Error Message */}
            {error && (
              <div className="bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400 p-4 rounded-lg flex items-center space-x-2">
                <ExclamationCircleIcon className="h-5 w-5 flex-shrink-0" />
                <p className="text-sm">{error}</p>
              </div>
            )}

            {/* Login Form */}
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label htmlFor="username" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Username
                </label>
                <input
                  type="text"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 
                    focus:ring-2 focus:ring-blue-500 focus:border-blue-500
                    bg-white dark:bg-gray-700 text-gray-900 dark:text-white
                    placeholder-gray-400 dark:placeholder-gray-500"
                  placeholder="Enter your username"
                  required
                />
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  Password
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                    onKeyUp={handleKeyUp}
                    className="w-full px-4 py-2 rounded-lg border border-gray-300 dark:border-gray-600 
                      focus:ring-2 focus:ring-blue-500 focus:border-blue-500
                      bg-white dark:bg-gray-700 text-gray-900 dark:text-white
                      placeholder-gray-400 dark:placeholder-gray-500"
                    placeholder="Enter your password"
                    required
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600 dark:hover:text-gray-300"
                  >
                    {showPassword ? (
                      <EyeSlashIcon className="h-5 w-5" />
                    ) : (
                      <EyeIcon className="h-5 w-5" />
                    )}
                  </button>
                </div>
                {isCapsLockOn && (
                  <p className="mt-1 text-sm text-yellow-600 dark:text-yellow-500">
                    Caps Lock is on
                  </p>
                )}
              </div>

              <button
                type="submit"
                className="w-full py-3 px-4 bg-gradient-to-r from-blue-500 to-blue-600 hover:from-blue-600 hover:to-blue-700
                  text-white font-medium rounded-lg shadow-lg hover:shadow-xl
                  transform transition-all duration-200 hover:scale-[1.02]
                  focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800"
              >
                Sign In
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLoginTailwind; 